import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img10 from '../../../assets/images/conocenos/img10.png'
import Textarea from '../../common/Textarea';
import Collapse from '../../common/Collapse';
import {get,post} from '../../../helpers/api';

const Biseccion=()=>{
  const   [data,setData]                        =   React.useState({})
  const   [items, setItems]                     =   React.useState([]);
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  const   [pdfs,setPdfs]                        =   React.useState([])

  React.useEffect(() => {
    // Verifica la existencia de la clave 'user' en localStorage al cargar el componente
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.section_pdf&&response.data.section_pdf) {
        setItems(response.data.section_pdf)          
      }
      if (response&&response.data&&response.data.pdfs_nuevos&&response.data.pdfs_nuevos) {
        setPdfs(response.data.pdfs_nuevos)          
      }
    })
  },[])

  const handleRemove=(row)=>{
    try {
      post("api/sections/destroy/"+row.id).then((response)=>{
        document.location.reload();
      })
    } catch (error) {
      console.log(error)
    }
  }

  
  return  <Container>
            <Row className="justify-content-center mb-4">
              <Col  className="text-start position-relative"  xs={12} md={10}>
                <div className="position-absolute text-1 ps-2 ps-md-5 text-white">
                  <h2 style={{fontSize:"1.5rem"}}><b>Estatutos</b></h2>
                  <p >
                    Conoce las normas que rigen nuestro fondo.
                  </p>                  
                </div>
                <Image src={img10} fluid/>
              </Col>
            </Row>
            <Row className="justify-content-center mb-4">
              <Col  className="text-start position-relative"  xs={12} md={10}>
                <Container>
                  {
                    isUserLoggedIn&&(
                      <Row className="justify-content-center">
                        <Col  className="text-center pt-4"  xs={12} md={3} >                        
                          <Textarea  id={"new"} alias={"pdfs-nuevos"} data={data} upload={true}/>
                        </Col>                  
                      </Row>                  
                    )
                  }
                  
                  <Row className="justify-content-start">
                    {
                      pdfs&&pdfs.map&&pdfs.map((row,key)=>{
                        return  <Col key={key} className="text-start pb-4 bg-white border mb-3"  xs={12} md={3}>
                                    <div dangerouslySetInnerHTML={{ __html: row.content }} />                                                                      
                                    <a className='btn btn-primary mr-1' href={row.image} target='_blank'>
                                      Descargar
                                    </a>

                                    {
                                      isUserLoggedIn&&(
                                        <div className='ms-1 btn btn-secondary' onClick={()=>{handleRemove(row)}}>
                                          Eliminar
                                        </div>
                                      )
                                    }
                                    
                                </Col>                  
                      })
                    }
                  </Row>                  
                  
                  
                </Container>
              </Col>
            </Row>  
          </Container>
}

export default Biseccion
