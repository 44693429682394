import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Example({openPQR,setOpenPQR,Component,onSubmit,title,size}) {

  const [submit,setSubmit]  = useState(false)

  return (
      <Modal show={openPQR} size={size||"lg"} onHide={()=>setOpenPQR(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title||"PQR"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {<Component submit={submit} setSubmit={setSubmit}/>}
        </Modal.Body>
        <Modal.Footer className="d-none">
          <Button  variant="secondary" onClick={()=>setOpenPQR(false)}>
            Cancelar
          </Button>
          <Button className="d-none" variant="primary" onClick={()=>setSubmit(true)}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

  );
}
export default Example
