import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';
import {  Empty } from '../../convenios/todos'

const Biseccion=()=>{

  const   [data,setData]          =   React.useState([])  
  const   [data2,setData2]        =   React.useState([])



  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/salud_medicina_complementaria").then((response)=>{
      if (response&&response.data&&response.data.salud_medicina_complementaria) {
        setData(response.data.salud_medicina_complementaria)  
      }      
    })
    get("api/s/salud_medicina_prehospitalaria").then((response)=>{
      if (response&&response.data&&response.data.salud_medicina_prehospitalaria) {
        setData2(response.data.salud_medicina_prehospitalaria)  
      }      
    })
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
              <Col  className="text-start"  xs={12} md={5}>
                  <h3>Complementarios</h3>
                  <p>Planes de medicina complementaria y preferencial</p>
                  {
                    data&&data.map&&data.map((row,key)=>
                      <div className="row mb-2" key={key}>
                        <div className="col-12">
                          <Empty data={row} />                          
                        </div>
                        <div className="col pt-4 h4 text-danger2 d-none">
                          {row.content}                           
                        </div>
                        {
                          isUserLoggedIn&&(
                            <Textarea   section_id="510"  
                                        alias="salud_medicina_complementaria" 
                                        sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                        id={key} 
                                        data={data} 
                                        upload={true}
                            />
                          )
                        }
                        
                      </div>                  
                    )
                  }   
                  {
                    isUserLoggedIn&&(
                      <Textarea section_id="510"  
                            alias="salud_medicina_complementaria" 
                            sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                            id={"new"} 
                            data={{}} 
                            upload={true}
                      />                  
                    )
                  }                  
              </Col>
              <Col  className="text-start"  xs={12} md={5}>
                  <h3>Prehospitalario</h3>
                  <p>Planes de medicina complementaria y preferencial</p>
                  {
                    data2&&data2.map&&data2.map((row,key)=>
                      <div className="row mb-2" key={key}>
                        <div className="col-12">
                          <Empty data={row} />                          
                        </div>
                        <div className="col pt-4 h4 text-danger2 d-none">
                          {row.content}
                        </div>
                        {
                          isUserLoggedIn&&(
                            <Textarea   section_id="510"  
                                        alias="salud_medicina_prehospitalaria" 
                                        sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                        id={key} 
                                        data={data2} 
                                        upload={true}
                            />
                          )
                        }                        
                      </div>                  
                    )
                  }  

                  {
                    isUserLoggedIn&&(
                      <Textarea section_id="503"  
                                alias="salud_medicina_prehospitalaria" 
                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                id={"new"} 
                                data={{}} 
                                upload={true}
                      />                                  
                    )
                  }
                  
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
