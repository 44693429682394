import Carousel from '../common/Carousel'
import Title from '../common/Title'
import Biseccion from '../common/vivienda/Biseccion'
import banner1 from '../../assets/images/vivienda/banner-programandoweb.png'
import SolicitudCreditoComponent from '../common/SolicitudCreditoComponent'


import React from 'react';
import {get} from '../../helpers/api';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{
  const   [data,setData] =React.useState([])
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      setData(response.data.items)
    })
  },[])
  return  <>
            <div className="">
              <Carousel title="Vivienda" text1="" text2="" classNameTitle="text-danger" src={banner1}/>
            </div>
            <Title label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h1 mt-3 text-blue"/>
            <Biseccion data={data}/>
            {/*
            <SolicitaCredito label=" de vivienda"/>
            * */}
            <SolicitudCreditoComponent/>
          </>
}

export default App
