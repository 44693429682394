import '../../App.css';
import oficina from '../../assets/images/home/boton_oficina_virtual.svg';
import whatsapp from '../../assets/images/icons/whatsapp.png'
import instagram from '../../assets/images/icons/instagram.png'
import youtube from '../../assets/images/icons/pse.png'

const SocialNetwork=()=>{
  return  <div className="position-fixed social-network-sidebar">
            <a rel="noreferrer" href="https://wa.me/573123281958" target="_blank">
              <img src={whatsapp} className="social-network" alt="programandoweb"/>
            </a>
            <a rel="noreferrer" href="https://private.fesol.com.co/zonap/#/"  >
              <img src={oficina} className="social-network" alt="programandoweb"/>
            </a>
            <a rel="noreferrer" href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=11429" target="_blank">
              <img src={youtube} className="social-network" alt="programandoweb"/>
            </a>
            <a rel="noreferrer" href="https://www.instagram.com/instafesol?igsh=dHlremYyeXA0Mmx0" target="_blank">
              <img src={instagram} className="social-network" alt="programandoweb"/>
            </a>
          </div>
}

export default SocialNetwork
