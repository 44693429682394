import React from 'react';
import Carousel from '../common/Carousel'
import Title from '../common/Title'
import Biseccion from '../common/nosotros/Biseccion'
import Biseccion2 from '../common/nosotros/Biseccion2'
import Biseccion3 from '../common/nosotros/Biseccion3'
import img1 from '../../assets/images/nosotros/nosotros-programandoweb.png'
import img2 from '../../assets/images/nosotros/nosotros-2-programandoweb.png'
import img3 from '../../assets/images/nosotros/nosotros3-programandoweb.png'
import banner1 from '../../assets/images/nosotros/banner.png'
import banner1_xs from '../../assets/images/nosotros/banner.png'

import {get} from '../../helpers/api';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{

  const   [data,setData] =React.useState([])
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      setData(response.data.items)
    })
  },[])

  return  <>
            <div className="">
              <Carousel src_xs={banner1_xs} title="Nosotros" type="2" classNameTitle=" text-danger " text1="" text2="" src={banner1}/>
            </div>
            <Title label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h2 mt-3 text-blue"/>
            <Title label={data[1]?htmlToReactParser.parse(data[1].content):false} className="text-center h6 mb-4 "/>
            <Biseccion  img={img1}
                        title={{label:"Aquí encontrarás aulas", className:"h5 text-blue text-start "}}
                        paragraph={{  label:data[2]?htmlToReactParser.parse(data[2].content):false, className:"p text-start mb-4"}}/>
            <Title label={data[3]?htmlToReactParser.parse(data[3].content):false} className="text-center h2 mt-3 text-danger2"/>
            <Title label={data[4]?htmlToReactParser.parse(data[4].content):false} className="text-center h6 mb-4 "/>
            <Biseccion2 img={img2}
                        title={{label:data[5]?htmlToReactParser.parse(data[5].content):false, className:"h4 text-blue text-justify mt-5 pt-5"}}
                        paragraph={{  label:data[6]?htmlToReactParser.parse(data[6].content):false, className:"p text-start mb-4"}}/>
            <Biseccion3 img={img3}
                        title={{label:data[6]?htmlToReactParser.parse(data[6].content):false, className:"h4 text-danger2 text-start mt-5 pt-5"}}
                        paragraph={{  label:data[7]?htmlToReactParser.parse(data[7].content):false, className:"p text-start mb-4"}}/>
          </>
}

export default App
