import React, { useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils, ContentState, convertFromHTML , convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';

const EditorEnriquecido = ({ defaultValue, onChange }) => {
  const [editorState, setEditorState] = useState(() => {
    if (defaultValue) {
      const blocksFromHTML    =   convertFromHTML(defaultValue);
      const state             =   ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(state);
    }
    return EditorState.createEmpty();
  });

  const [activeStyles, setActiveStyles] = useState([]);

  useEffect(() => {
    // Actualizar el estado del editor cuando cambia el defaultValue
    if (defaultValue) {
      const blocksFromHTML = convertFromHTML(defaultValue);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [defaultValue]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    // Obtener el contenido HTML actualizado del editor y pasarlo a la función onChange
    const contentState      = newEditorState.getCurrentContent();
    const rawContentState   =   convertToRaw(contentState);

    const markup = draftToHtml(
      rawContentState,       
    );
    onChange(markup);

    /*
    // Obtener el texto actualizado del editor y pasarlo a la función onChange
    const contentState  =   newEditorState.getCurrentContent();
    const plainText     =   contentState.getPlainText('\u0001');
    onChange(plainText);
    */
  };  

  const handleFormatClick = (format) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, format));
    toggleActiveStyle(format);
  };

  const handleBlockTypeClick = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    toggleActiveStyle(blockType);
  };

  const toggleActiveStyle = (style) => {
    const isActive = activeStyles.includes(style);
    const updatedStyles = isActive
      ? activeStyles.filter((activeStyle) => activeStyle !== style)
      : [...activeStyles, style];
    setActiveStyles(updatedStyles);
  };

  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ];

  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
  ];

  return (
    <div style={{ minWidth: "60%" }}>
      <div className="btn-group mb-2" role="group">
        {INLINE_STYLES.map((style) => (
          <button
            key={style.label}
            onClick={() => handleFormatClick(style.style)}
            className={`btn ${activeStyles.includes(style.style) ? 'btn-secondary' : 'btn-primary'} btn-sm`}
          >
            {style.label}
          </button>
        ))}
      </div>
      <div className="btn-group" role="group">
        {BLOCK_TYPES.map((type) => (
          <button
            key={type.label}
            onClick={() => handleBlockTypeClick(type.style)}
            className={`btn ${activeStyles.includes(type.style) ? 'btn-secondary' : 'btn-primary'} btn-sm`}
          >
            {type.label}
          </button>
        ))}
      </div>
      <div className='card mt-2'>
        <div className='card-body' style={{ minHeight: '300px' }}>
          <Editor
            editorState={editorState}
            onChange={handleEditorChange}
            placeholder="Escriba acá el texto..."
          />
        </div>
      </div>
    </div>
  );
};

export default EditorEnriquecido;
