import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/sedeSocial/img1.png'
import img2 from '../../../assets/images/sedeSocial/img2.png'
import img3 from '../../../assets/images/sedeSocial/img3.png'

import whatsapp from '../../../assets/images/icons/whatsapp.png'
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';

import {
          Empty,
          Unireminton,
          CampoAlto
        } from '../../convenios/todos'

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Biseccion=({data})=>{

  const   [data2,setData2]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/programas_tecnicos").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData2(response.data.items)  
      }      
    })    
  },[])


  return  <div className='programandoweb-blog'>
            <Container>
              <Row className="justify-content-center">
                <Col  className="text-start pt-1 text-center"  xs={12}>
                  {data[1]?htmlToReactParser.parse(data[1].content):false}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col  className="text-start pt-4"  xs={12} md={5}>
                  <Image src={img1} fluid/>
                </Col>
                <Col  className="text-start pt-5"  xs={12} md={4}>
                    {
                      data&&data[2]&&(
                        <Textarea component={data[2]?htmlToReactParser.parse(data[2].content):false} id={2} data={data}/>          
                      )
                    }
                    {data[2]?htmlToReactParser.parse(data[2].content):false}
                    <h3 className="text-blue mt-2 mb-2">
                      Aquí encontrarás aulas
                    </h3>

                    {
                      data&&data[3]&&(
                        <Textarea component={data[3]?htmlToReactParser.parse(data[3].content):false} id={3} data={data}/>          
                      )
                    }

                    {data[3]?htmlToReactParser.parse(data[3].content):false}
                </Col>
            </Row>
          </Container>
          <Container fluid className="textura1">
            <Row className="justify-content-center " >
              <Col  className="text-start pt-4 pb-5"  xs={12} md={10}>
                {
                  /*
                    <h3 className="text-danger text-center mt-5"><b>{data[4]?htmlToReactParser.parse(data[4].content):false}</b></h3>  
                  * */
                }
                
                <div className="text-center">
                    {
                      data&&data[5]&&(
                        <Textarea component={data[5]?htmlToReactParser.parse(data[5].content):false} id={5} data={data}/>          
                      )
                    }<br/>
                    {data[5]?htmlToReactParser.parse(data[5].content):false}
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center " >
              <Col className="text-start pt-4 pb-5" xs={12} md={5}>
                <div className="">
                  <Image src={img3} fluid />
                </div>
              </Col>
              <Col className="text-start pt-4 pb-5 order-md-last" xs={12} md={5}>
                <div className="">
                  {
                    data && data[7] && (
                      <Textarea component={data[7] ? htmlToReactParser.parse(data[7].content) : false} id={7} data={data} />
                    )
                  }
                  <br />
                  {data[7] ? htmlToReactParser.parse(data[7].content) : false}
                </div>
              </Col>
              <Col className="text-start pt-4 pb-5" xs={12} md={5}>
                <div className="">
                  <Image src={img2} fluid />
                </div>
              </Col>
              <Col  className="text-start pt-4 pb-5"  xs={12} md={5}>
                {
                  /*
                  <h3 className="text-danger  mt-5"><b>{data[8]?htmlToReactParser.parse(data[8].content):false}</b></h3>  
                  * */
                }
                
                <div className="">
                    {
                      data&&data[9]&&(
                        <Textarea component={data[9]?htmlToReactParser.parse(data[9].content):false} id={9} data={data}/>          
                      )
                    }<br/>
                  {data[9]?htmlToReactParser.parse(data[9].content):false}
                </div>
                <div className="h3">
                  <a rel="noreferrer" href="https://wa.me/573102457821" target="_blank">
                    <img src={whatsapp} className="col-1" alt="programandoweb"/>3102457821
                  </a>
                </div>

                <div className="d-none row mt-2">
                  <div className="col-6">
                    <CampoAlto className="img-fluid"/>
                  </div>
                  <div className="col-6">
                    <Unireminton className="img-fluid"/>
                  </div>
                </div>

                <div className="row justify-content-center mt-1 pt-1">
                  {
                    data2&&data2.map&&data2.map((row,key)=>{
                      return  <div className="col-12 col-sm-6" key={key}>
                                <Empty data={row}/>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="530"  
                                                alias="programas_tecnicos_2024_ivoolve" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data2} 
                                                upload={true}
                                    />
                                  )
                                }
                              </div>
                    })
                  }
                  
                </div>

                {
                  isUserLoggedIn&&(
                    <div className='row'>
                      <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                        <Textarea section_id="530"  
                                  alias="programas_tecnicos_2024_ivoolve" 
                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                  id={"new"} 
                                  data={{}} 
                                  upload={true}
                        /> Nuevos items 
                      </div>
                    </div>    
                  )
                } 
              </Col>
            </Row>

          </Container>
          </div>
}

export default Biseccion
