import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/servicios/img1.png'
import img2 from '../../../assets/images/servicios/img2.png'
import img3 from '../../../assets/images/servicios/img3.png'
import img4 from '../../../assets/images/servicios/img4.png'
import img5 from '../../../assets/images/servicios/img5.png'
import img6 from '../../../assets/images/servicios/img6.png'
import img7 from '../../../assets/images/servicios/img7.png'


const Biseccion=({img,title,paragraph})=>{
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={8}>
                <div className="row justify-content-center">
                  <a href="./fondo_mutual" className="col-6 col-sm-3 mb-2">
                    <Image src={img1} className="img-fluid" />
                    <div className="text-label">Fondo Mutual</div>
                  </a>
                  <a href="./sede_social" className="col-6 col-sm-3 mb-2">
                    <Image src={img2} className="img-fluid"/>
                    <div className="text-label">Sede social</div>
                  </a>
                  <a href="./educacion" className="col-6 col-sm-3 mb-2">
                    <Image src={img3} className="img-fluid"/>
                    <div className="text-label">Educación</div>
                  </a>
                  <a href="./entretenimiento" className="col-6 col-sm-3 mb-2">
                    <Image src={img4} className="img-fluid"/>
                    <div className="text-label">Entretenimiento y Bienestar</div>
                  </a>
                  <a href="./seguros" className="col-6 col-sm-3 mb-2">
                    <Image src={img5} className="img-fluid"/>
                    <div className="text-label">Seguros</div>
                  </a>
                  <a href="./salud" className="col-6 col-sm-3 mb-2">
                    <Image src={img6} className="img-fluid"/>
                    <div className="text-label">Salud</div>
                  </a>
                  <a href="./turismo" className="col-6 col-sm-3 mb-2">
                    <Image src={img7} className="img-fluid"/>
                    <div className="text-label">Turismo</div>
                  </a>
                </div>
              </Col>

            </Row>

          </Container>
}

export default Biseccion
