import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import {get} from '../../../helpers/api';
import Textarea from '../../common/Textarea';
import Title from '../../common/Title'
import {
          Empty,
          
        } from '../../convenios/todos';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();
        

const Biseccion=({img,paragraph})=>{
  const   [data,setData]          =   React.useState([])  
  const   [data2,setData2]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response.data && response.data.items) {
        setData2(response.data.items)
      }
    })
    get("api/s/bienestra_gym").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5} >
                  
                <div >
                  {
                    data2&&data2[2]&&data2[2].content&&data2[2].content!==''&&(
                      <Title id={data2[2]} editable={data2[2].content} label={data2[2]?htmlToReactParser.parse(data2[2].content):false} className={paragraph.className + " text-center  mt-3"}/>
                    )
                  }    
                </div>

                
                <div className="row justify-content-center mt-5 pt-5">
                  <Col  className="text-start pt-4 d-sm-none"  xs={12} md={5}>
                    <Image src={img} fluid/>
                  </Col>
                  {
                    data&&data.map&&data.map((row,key)=>{
                      return  <div className="col-12 col-sm-6" key={key}>
                                <Empty data={row}/>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="517"  
                                                alias="bienestra_gym" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={true}
                                    />
                                  )
                                }
                              </div>
                    })
                  }
                  
                </div>
                {
                  isUserLoggedIn&&(
                    <div className='row'>
                      <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                        <Textarea section_id="517"  
                                  alias="bienestra_gym" 
                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                  id={"new"} 
                                  data={{}} 
                                  upload={true}
                        /> Nuevos items 
                      </div>
                    </div>    
                  )
                } 
              </Col>              
              <Col className="text-center" xs={12} md={4}>
                <div className='d-none d-sm-block'>
                  <Image src={img} fluid/>  
                </div>                  
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
