import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/fondoMutual/img1.png'
import img2 from '../../../assets/images/fondoMutual/img2.png'

import img3 from '../../../assets/images/fondoMutual/img3.png'
import img4 from '../../../assets/images/fondoMutual/img4.png'
import img5 from '../../../assets/images/fondoMutual/img5.png'

import Textarea from '../../common/Textarea';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();



const Biseccion=({title,paragraph,data})=>{
  return  <>
            <Container className="mb-3">
              <Row className="justify-content-center">
                <Col  className="text-start pt-4"  xs={12} md={10}>
                  {
                    data&&data[1]&&(
                      <Textarea component={data[1]?htmlToReactParser.parse(data[1].content):false} id={1} data={data}/>          
                    )
                  }
                  {
                    data[1]?htmlToReactParser.parse(data[1].content):false
                  }
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col  className="text-start pt-4 d-sm-none"  xs={12} md={5}>
                  <Image src={img2} fluid/>
                </Col>
                <Col  className="text-start pt-4"  xs={12} md={5}>
                  <div className="row">
                    <div className="col-4">
                      <Image src={img3} fluid/>
                    </div>
                    <div className="col-8">
                      <h3 className="text-danger mt-4">
                        {data[2]?htmlToReactParser.parse(data[2].content):false}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <Image src={img4} fluid/>
                    </div>
                    <div className="col-8">
                      <h3 className="text-blue mt-4">
                        {data[3]?htmlToReactParser.parse(data[3].content):false}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <Image src={img5} fluid/>
                    </div>
                    <div className="col-8">
                      <h3 className="text-danger mt-4">
                        {data[4]?htmlToReactParser.parse(data[4].content):false}
                      </h3>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="card border-blue">
                        <div className="row">
                          <div className="col-1 ">

                          </div>
                          <div className="col-6  text-danger h5 ">
                            <b>¿Qué antiguedad debe tener el asociado para aplicar el beneficio?</b>
                          </div>
                          <div className="col-4 h1 text-blue text-center pt-2">
                            <b>2 años</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </Col>
                <Col className="text-center" xs={12} md={4}>
                  <div className='d-none d-sm-block'>
                    <Image src={img2} fluid/>
                  </div>                  
                </Col>
              </Row>
            </Container>
            <Container fluid className="textura1">
              <Row className="justify-content-center pt-4 pb-5">
                <Col  className="text-center "  xs={12} md={4}>
                  <Image src={img1} fluid/>
                </Col>
                <Col  className="text-start pt-4"  xs={12} md={5}>
                  <div className="pt-0 text-blue h3">
                    
                    {data[5]?htmlToReactParser.parse(data[5].content):false}

                  </div>
                  <div>
                      <div className="text-danger"><br/><b>Aplica Reglamento</b></div>
                      <div>
                        {
                          data&&data[6]&&(
                            <Textarea component={data[6]?htmlToReactParser.parse(data[6].content):false} id={6} data={data}/>          
                          )
                        }
                        
                        {data[6]?htmlToReactParser.parse(data[6].content):false}

                        {
                          data&&data[7]&&(
                            <Textarea component={data[7]?htmlToReactParser.parse(data[7].content):false} id={7} data={data}/>          
                          )
                        }
                        {data[7]?htmlToReactParser.parse(data[7].content):false}
                      </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
}

export default Biseccion
