import React from 'react';
import Carousel from 'react-multi-carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Textarea from '../common/Textarea';
import 'react-multi-carousel/lib/styles.css';
/*
import img1 from '../../assets/images/clientes/cliente (1).png';
import img2 from '../../assets/images/clientes/cliente (2).png';
import img3 from '../../assets/images/clientes/cliente (3).png';
import img4 from '../../assets/images/clientes/cliente (4).png';
import img5 from '../../assets/images/clientes/cliente (5).png';
import img6 from '../../assets/images/clientes/cliente (6).png';
import img7 from '../../assets/images/clientes/cliente (7).png';
import img8 from '../../assets/images/clientes/cliente (8).png';
import img9 from '../../assets/images/clientes/cliente (9).png';
import img10 from '../../assets/images/clientes/cliente (10).png';
import img11 from '../../assets/images/clientes/cliente (11).png';
import img12 from '../../assets/images/clientes/cliente (12).png';
import img13 from '../../assets/images/clientes/cliente (13).png';
import img14 from '../../assets/images/clientes/cliente (14).png';
import img15 from '../../assets/images/clientes/cliente (15).png';
import img16 from '../../assets/images/clientes/cliente (16).png';
import img17 from '../../assets/images/clientes/cliente (17).png';
import img18 from '../../assets/images/clientes/cliente (18).png';
import img19 from '../../assets/images/clientes/cliente (19).png';
import img20 from '../../assets/images/clientes/cliente (20).png';
import img21 from '../../assets/images/clientes/cliente (21).png';
import img22 from '../../assets/images/clientes/cliente (22).png';
import img23 from '../../assets/images/clientes/cliente (23).png';
import img24 from '../../assets/images/clientes/cliente (24).png';
import img25 from '../../assets/images/clientes/cliente (25).png';*/
import {get,authenticatedPost} from '../../helpers/api';



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

//const clients = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25]
let clients = []

const App   = ()=>{
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  const   [clients, setClients]                 =   React.useState([]);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);
  
  React.useEffect(()=>{
    get("api/s/clientes_carousel_new").then((response)=>{
      if (response&&response.data&&response.data.clientes_carousel_new&&response.data.clientes_carousel_new.map) {
        setClients(response.data.clientes_carousel_new);        
      }
    })    
  },[])

  const handleClick=(row)=>{
    authenticatedPost('api/banners/delete', { del: "edit/", id:row.id }, true).then(response=>{
        
      if (response&&response.data) {
          document.location.reload();
          //setDataset(response.data);
      } 
                             
    }); 
  }

  return    <>
              <Carousel
                          swipeable={false}
                          draggable={false}
                          showDots={true}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={800}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                        >
                          {clients.map((row,key)=>{
                            return  <div key={key}>
                                      {
                                        isUserLoggedIn&&(
                                          <div className='btn btn-primary btn-sm' onClick={()=>{handleClick(row)}}>
                                            Borrar
                                          </div>
                                        )
                                      }
                                      <div>
                                        <img src={row&&row.image} className="img-fluid" alt="programandoweb"/>
                                      </div>
                                    </div>
                          })}
              </Carousel>
              <Container>
                {
                  isUserLoggedIn&&(
                    <Row className="justify-content-center">
                      <Col  className="text-center pt-4"  xs={12} md={3} >                        
                        <Textarea section_id="503"  alias="clientes_carousel_new" sizes="Las medidas para banner en cabecera son: 264px x 166px"  id={"new"} data={{}} upload={true}/>
                      </Col>                  
                    </Row>                  
                  )
                }
              </Container>              
            </>

}

export default App
