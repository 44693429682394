import React from 'react';
import { useLocation } from 'react-router-dom';
import { authenticatedPost } from '../../../helpers/api';
import { Form, Table } from 'react-bootstrap';
import FormUpload from './Form';

const limit = 2000000;

const UploadFile = ({ dataset, setDataset, inputValue, setInputValue }) => {

    const [add, setAdd]           =       React.useState(false);

    // Obtén la ubicación actual del router
    const location = useLocation();

    // Accede a la propiedad pathname de la ubicación para obtener la URL
    const currentUrl = location.pathname;

    // Maneja el cambio en el campo de tipo texto
    const setFileValue = async (file) => {
        if (file) {
            if (file.size >= limit) {
                alert("Imagen supera límite permitido de (" + limit / 1000000 + ") MB");
            }
            const response = await authenticatedPost('api/multimedia/uploadfile', { images: file, grupo: "prueba" }, true);
            setInputValue((prev) => {
                return {
                    ...prev,
                    pathname: location.pathname,
                    data: response.data,
                };
            });
        }
    };

    const onChange = (e) => {
        setInputValue((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value || '',
            };
        });
    };

    const handleDelete=(row)=>{
        
        if (row&&row.id) {
            authenticatedPost('api/banners/delete', { del: "edit/", id:row.id }, true).then(response=>{

                if (response&&response.data) {
                    document.location.reload();
                    //setDataset(response.data);
                }                

            });            
        }
        
    }

    

    return (
        <>
            {
                add&&(
                    <div className='text-end mb-2'>
                        <div className='btn btn-primary btn-sm' onClick={()=>setAdd(false)}>
                            Cancelar
                        </div>
                    </div>
                )
            }
            {
                add&&(
                    <div >
                        <FormUpload inputValue={inputValue} setInputValue={setInputValue}/>
                    </div>
                )
            }
            {
                !add&&(
                    <div className='text-end mb-2'>
                        <div className='btn btn-primary btn-sm' onClick={()=>setAdd(true)}>
                            Agregar
                        </div>
                    </div>
                )
            }
            {
                !add&&(
                    <>
                        <div className="alert alert-primary" role="alert">
                            Las medidas para banner en cabecera son: 1922px x 750px
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#</th>
                                    {/* Agrega encabezados de columna según tus datos */}
                                    <th>Image</th>
                                    <th>Borrar</th>
                                    {/* ... */}
                                </tr>
                            </thead>
                            <tbody>
                                {/* Mapea tus datos para renderizar filas */}
                                {dataset.map((item, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>
                                            <img style={{height:"40px"}} src={item.src} alt="Programandoweb" className='img-fluid'/>                                
                                        </td> {/* Sustituye "columna1" con el nombre real de la propiedad */}
                                        <td className='text-center'>
                                            <div className='btn btn-primary btn-sm' onClick={()=>handleDelete(item)}>
                                                X
                                            </div>                                
                                        </td>                             
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                )
            }           

        </>
    );
};

export default UploadFile;
