import React from 'react';
import Carousel from '../common/conocenos/CarouselNew'
import Title from '../common/Title'
import Biseccion from '../common/conocenos/Biseccion'
import Biseccion5 from '../common/conocenos/Biseccion5'
import Biseccion4 from '../common/conocenos/Biseccion4'
import img1 from '../../assets/images/conocenos/nosotros-programandoweb.jpg'
import banner1 from '../../assets/images/conocenos/banner-programandoweb.png'
import {get} from '../../helpers/api';


const items   =   [
                    {
                      label:"",
                      title:"",
                      src:banner1,
                    },
                  ]

const App=()=>{


  const   [data,setData] =React.useState([])

  const   [directivos,setDirectivos] =React.useState([])

  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response.data && response.data.items) {
        setData(response.data.items)
      }
    })
  },[])

  React.useEffect(()=>{
    //const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    /*
    get(endpoint).then((response)=>{
      setData(response.data.items)
    })
    */

    get("api/s/section_conocenos").then((response)=>{
      if (response&&response.data&&response.data.section_conocenos) {
        setDirectivos(response.data.section_conocenos);
      }      
    })

  },[])

  return  <>
            <div className="ps-1 pe-1">
              <Carousel title="Conócenos" text="" data={items}/>
            </div>
            {
             data&&data[0]&&(
                <Title id={data&&data[0]} editable={data&&data[0].content} label={data&&data[0]?<div dangerouslySetInnerHTML={{ __html: data&&data[0].content }} />:false} className="text-center h5 mt-3 text-dark"/>
              )              
            }

            {
             data&&data[1]&&(
                <Title id={data&&data[1]} editable={data&&data[1].content} label={data&&data[1]?<div dangerouslySetInnerHTML={{ __html: data&&data[1].content }} />:false} className="text-center h5 mb-4 text-blue"/>
              )              
            }

            {
             data&&data[2]&&(
                <Title id={data&&data[2]} editable={data&&data[2].content} label={data&&data[2]?<div dangerouslySetInnerHTML={{ __html: data&&data[2].content }} />:false} className="text-center p mb-4 text-dark"/>
              )              
            }
            
            <Biseccion  img={img1}
                        title={{label:"Aquí encontrarás aulas", className:"h5 text-blue text-start "}}
                        paragraph={{  label:"FESOL le ofrece a los asociados diferentes cursos como natación o primeros auxilios, además en diplomados en caso de ser enfermeros o estudiante de enfermería.", className:"p text-start mb-4"}}/>

            <div className="textura1">
              {
                data&&data[3]&&(
                  <Title id={data&&data[3]} editable={data&&data[3].content} label={data&&data[3]?<div dangerouslySetInnerHTML={{ __html: data&&data[3].content }} />:false} className="text-center h2 mt-3 text-danger2"/>
                )              
              }
              {
                data&&data[4]&&(
                  <Title id={data&&data[4]} editable={data&&data[4].content} label={data&&data[4]?<div dangerouslySetInnerHTML={{ __html: data&&data[4].content }} />:false} className="text-center p mb-4 text-dark"/>
                )              
              }
              <Biseccion5 data={directivos}/>                            
            </div>
            <Biseccion4/>


          </>
}

export default App
