import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/vivienda/img-1.png'

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const Biseccion=({title,paragraph,data})=>{
  return  <Container>
            <Row className="justify-content-center">
              <Col  className="text-start pt-4"  xs={12} md={5}>
                {
                  data[1]?htmlToReactParser.parse(data[1].content):false
                }
                <div >
                  {data[2]?htmlToReactParser.parse(data[2].content):false}
                </div>
                {data[3]?htmlToReactParser.parse(data[3].content):false}
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <Image src={img1} fluid/>
              </Col>
            </Row>
          </Container>
}

export default Biseccion
