import React from 'react';
import { useLocation } from 'react-router-dom';
import { authenticatedPost } from '../../../helpers/api';
import { Form } from 'react-bootstrap';


const limit = 2000000
const UploadFile = ({setInputValue, skipUrl, grupo, sizes, skipLabel}) => {

    

    // Obtén la ubicación actual del router
    const location = useLocation();

    // Accede a la propiedad pathname de la ubicación para obtener la URL
    const currentUrl = location.pathname;

    // Maneja el cambio en el campo de tipo texto
    const setFileValue = async (file) => {
        if (file) {
            if (file.size>=limit) {
                alert("Imagen supera límite permitido de ("+limit/1000000+") MB")
            }            
            const response  =   await authenticatedPost('api/multimedia/uploadfile', { images: file,grupo:grupo||"prueba" }, true).then(response=>{
                setInputValue((prev) => {
                    return {
                        ...prev,
                        pathname: location.pathname,
                        data: response.data
                    };
                });
            })                
        }     
        
    };

    const onChange = (e) => {
        setInputValue((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value||'',
            };
        });
    };

    return (
        <Form>
            {!skipLabel&&(
                    <div>
                        <div className="alert alert-primary" role="alert">
                            {
                                sizes||"Las medidas para banner en cabecera son: 1921px x 541px"
                            }
                        </div>
                    </div>
                )
            }
            
            <Form.Group controlId="formFile" className="mb-3">
                {!skipLabel&&(
                        <Form.Label>Seleccionar archivo</Form.Label>
                    )
                }
                <Form.Control type="file" onChange={(e) => setFileValue(e.target.files[0])} />
            </Form.Group>
            {
                !skipUrl&&(
                    <Form.Group controlId="formText" className="mb-3">
                        <Form.Label>Url</Form.Label>
                        <Form.Control name="href" type="text" onChange={onChange} />
                    </Form.Group>            
                )
            }            
        </Form>
    );
};

export default UploadFile;
