import React from 'react';
import { useLocation } from 'react-router-dom';
import MiModal from '../EditSlider/Modal';
import { post } from '../../../helpers/api';
import Form from './Form';

const Upload=({data,id})=>{

    const [isUserLoggedIn, setIsUserLoggedIn]   =       React.useState(false);
    const [showModal, setShowModal]             =       React.useState(false);
    const [inputValue, setInputValue]           =       React.useState('');

    const location      =   useLocation();
    const currentUrl    =   location.pathname;
    

    React.useEffect(() => {        
        const userExists = localStorage.getItem('user');
        setIsUserLoggedIn(!!userExists);
    }, []);

    const handleButtonClick = () => {
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSaveChanges = async () => {
        try {            
            if (inputValue!=='') {
                //console.log(inputValue)
                // Envía los cambios al backend utilizando FormData
                const response = await post('api/banners/add/0',   { 
                                                                                    group: currentUrl,
                                                                                    name:"Un banner desde el front",
                                                                                    href:inputValue.href||"#",
                                                                                    url:inputValue.data
                                                                                }, true);
                document.location.reload()    
            }
        } catch (error) {
            console.error('Error al comunicarse con el backend:', error);
        }
    };

    if (!isUserLoggedIn) {
        return;
    }


    return      <div className="container">
                    <div className="row">
                        <div className="col-12 position-relative">
                            <div className="position-absolute top-0 end-0 z-index-100 pt-2" style={{zIndex:100}}>
                                {
                                    isUserLoggedIn&&(
                                        <div className='btn btn-secondary btn-sm' onClick={handleButtonClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16"  disabled={!isUserLoggedIn}>
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
                                            </svg>                  
                                        </div>
                                    )
                                }
                            </div>  
                        </div>
                    </div>
                    <MiModal    show={showModal} 
                                onHide={handleCloseModal} 
                                handleCloseModal={handleCloseModal}
                                handleSaveChanges={handleSaveChanges}
                                component={ <Form inputValue={inputValue} setInputValue={setInputValue}/> }                        
                    /> 
                </div>
}
export default Upload;