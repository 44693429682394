import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img0 from '../../../assets/images/bienestar/nosotros3-programandoweb.png'

/*
import {
          Cinemark,
          CineColombia,
          CineProcinal,
          MundoAventuras,
          Salitre,
          Royal
        } from '../../convenios/todos';
*/

import {get} from '../../../helpers/api';
import Textarea from '../../common/Textarea';
import {
          Empty,
          
        } from '../../convenios/todos'


const Biseccion=()=>{

  const   [data,setData]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    get("api/s/bienestra_convenio").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <div className={"h4 text-danger2 text-start mt-5 pt-5"}>

                </div>
                <div className="row justify-content-center">
                  <Col  className="text-start pt-4 d-sm-none"  xs={12} md={5}>
                    <Image src={img0} fluid/>
                  </Col>

                  {
                    data&&data.map&&data.map((row,key)=>{
                      return  <div className="col-12 col-sm-6" key={key}>
                                <Empty data={row}/>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="518"  
                                                alias="bienestra_convenio" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={true}
                                    />
                                  )
                                }
                              </div>
                    })
                  }                  
                </div>
                {
                  isUserLoggedIn&&(
                    <div className='row'>
                      <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                        <Textarea section_id="518"  
                                  alias="bienestra_convenio" 
                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                  id={"new"} 
                                  data={{}} 
                                  upload={true}
                        /> Nuevos items 
                      </div>
                    </div>    
                  )
                } 
              </Col>
              <Col className="text-center" xs={12} md={4}>
                <div className='d-none d-sm-block'>
                  <Image src={img0} fluid/>  
                </div>                  
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
