import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import img1 from '../../assets/images/clients/cliente-programandoweb1.png';
import img2 from '../../assets/images/clients/cliente-programandoweb2.png';
import img3 from '../../assets/images/clients/cliente-programandoweb3.png';
import img4 from '../../assets/images/clients/cliente-programandoweb4.png';
import img5 from '../../assets/images/clients/cliente-programandoweb5.png';
import img6 from '../../assets/images/clients/cliente-programandoweb6.png';
import img7 from '../../assets/images/clients/cliente-programandoweb7.png';
import img8 from '../../assets/images/clients/cliente-programandoweb8.png';
import Title from './Title';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const clients = [img1,img2,img3,img4,img5,img6,img7,img8,]

const App=({data})=>{

  if (data&&!data.map) {
    return;
  }

  return    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={5500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px mb-4"
                      >
                        {data&&data.map&&data.map((row,key)=>{
                          return  <div key={key} className="position-relative balance p-2">
                                    <div className="position-absolute text-label">
                                      {row.label}
                                    </div>
                                    <img src={row.img} className="img-fluid" alt="programandoweb"/>
                                    <div>
                                      < Title id={data[key]} editable={data[key].content} alias="balance_social" />
                                    </div>
                                  </div>
                        })}
            </Carousel>

}

export default App
