import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { post } from '../../helpers/api';
import { useNavigate } from 'react-router-dom';
import MiModal from './EditSlider/Modal';

const Title = ({ label, className, editable , id, alias}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    // Verifica la existencia de la clave 'user' en localStorage al cargar el componente
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      // Envía los cambios al backend utilizando FormData
      const response = await post('api/sections/edit/'+id.id, { content: inputValue, alias:alias||"" }, true);
      document.location.reload()
      // Resto del código...
    } catch (error) {
      console.error('Error al comunicarse con el backend:', error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-11">
          <div className={className}>
            <div >
              {label}            
              {
                id&&isUserLoggedIn&&(
                  <div className='btn btn-primary' onClick={handleButtonClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16"  disabled={!isUserLoggedIn}>
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
                  </svg>                  
                  </div>
                )
              }
            </div>
            <MiModal  show={showModal} 
                      onHide={handleCloseModal} 
                      handleCloseModal={handleCloseModal}
                      handleSaveChanges={handleSaveChanges}
                      component={ <Form.Group controlId="formBasicText">
                                    <Form.Control
                                        name="content"
                                        type="text"
                                        defaultValue={editable}
                                        onChange={handleInputChange}
                                    />
                                  </Form.Group>
                                }  
            />            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
