import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/ahorros/img1.png'
import img2 from '../../../assets/images/ahorros/img2.png'
import Textarea from '../../common/Textarea';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const Biseccion=({title,paragraph,data})=>{
  return  <Container>
            {
              data&&data[1]&&(
                <Row className="justify-content-center">
                  <Col  className="text-start"  xs={12}>
                      {data[1]?htmlToReactParser.parse(data[1].content):false}
                  </Col>
                  <Col  className="text-center"  xs={12} md={4}>
                    <Image src={img1} fluid/>
                  </Col>
                  <Col  className="text-start pt-4"  xs={12} md={5}>
                    <div className="pt-5 text-danger2 h3">
                      Ahorro permanente
                    </div>
                    <Textarea component={data[2]?htmlToReactParser.parse(data[2].content):false} id={2} data={data}/>      
                    {data[2]?htmlToReactParser.parse(data[2].content):false}
                  </Col>
                </Row>
              )
            }
            
            {
              data&&data[3]&&(
                <Row className="justify-content-center">
                  <Col  className="text-start pt-4"  xs={12} md={5}>
                    <div className="pt-5 text-blue h3">
                      Ahorro Programado para Vivienda
                    </div>
                    <Textarea component={data[3]?htmlToReactParser.parse(data[3].content):false} id={3} data={data}/>
                    {data[3]?htmlToReactParser.parse(data[3].content):false}
                  </Col>
                  <Col  className="text-center"  xs={12} md={4}>
                    <Image src={img2} fluid/>
                  </Col>
                </Row>
              )
            }

            
          </Container>
}

export default Biseccion
