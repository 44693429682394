import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
const Title=({title,className,md,xs,classNameTitle,btn,img})=>{
  return    <Col className={className} xs={xs} md={md}>
              <div className={classNameTitle}>{title}</div>
              {img?<div className="p-xs-5 p-md-3 img">
                      <Image  fluid src={img} alt="Programandoweb logo" />
                    </div>:false}
              {
                btn?  <a className={btn.className} href={btn.href}>
                        {btn.label}
                      </a>:false
              }
            </Col>
}

export default Title
