
const Slider=({banner,event,title,text1,text2,src,src_xs,url,classNameBtn,classNameTitle,classNameImg,type,classNameContentHeader,classNameText2,textUrl})=>{

  const event_  = ()=>{
    if (event) {
      //console.log(event);
      event(true)
    }
  }

  return <div className={event?"cursor-pointer position-relative ":" position-relative "} onClick={event_}>

            <div className={classNameContentHeader?classNameContentHeader:type?"content-header2 position-absolute":"content-header position-absolute"}>
                <h2 className={classNameTitle}>
                  {title}
                </h2>
                <div className={classNameText2+" text p-xs-2"}>
                  {text1}
                  <br/>
                  {text2}
                </div>
                {url?<div className="text-end">
                  <a href={url} className={classNameBtn?classNameBtn:"btn btn-primary text-white btn-conocenos col-6 col-sm-4 mt-3"}>{textUrl||"Ver más"}</a>
                </div>:false}
              </div>
              <div >
                <div className=" d-none d-sm-block " >
                  {
                    banner&&(
                      <img  className={" w-100 " +classNameImg} src={banner.image||banner.url||src} alt="Programandoweb"/>
                    )
                  }                  
                </div>
                <div className="d-xs-block d-sm-none">
                  {
                    banner&&(
                      <img  className={" w-100 " +classNameImg} src={banner.image||banner.url||src_xs || src} alt="Programandoweb"/>
                    )
                  }                  
                </div>
              </div>

          </div>
}

export default Slider
