import Form from 'react-bootstrap/Form';

function SwitchExample({onChange,name,label,defaultChecked}) {
  return (
    <Form>
      <Form.Check 
        defaultChecked={defaultChecked===1?true:false}
        name={name}
        type="switch"
        id="custom-switch"
        label={label}
        onChange={onChange}
      />      
    </Form>
  );
}

export default SwitchExample;