import React from 'react';
import Carousel from '../common/CarouselNew'
import Title from '../common/Title'
import CarouselGood from '../common/CarouselGood';
import banner1 from '../../assets/images/crediya/banner-programandoweb.png'
import banner1_xs from '../../assets/images/crediya/banner-programandoweb.png'
import SolicitudCreditoComponent from '../common/SolicitudCreditoComponent';
import {get} from '../../helpers/api';
import Upload from '../common/Upload';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const data2_=[
  {
    title:"",
    label:"",
    src:banner1_xs,
    url:"https://api.fesol.com.co/public/DELEGADOS_PAGINA.pdf"
  },  
]

const App=()=>{
  const   [data,setData]        =   React.useState([])
  const   [data2,setData2]      =   React.useState(data2_)
  const   [banner,setBanner]    =   React.useState(false)
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s/section_crediya":"api/s/section_crediya")
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      /*
      if (response&&response.data&&response.data.section_crediya&&response.data.section_crediya[0]) {
        setBanner(response.data.section_crediya[0])          
      }*/
      if (response.data && response.data.banners) {
        response.data.banners.src=response.data.banners.url;
        setData2([response.data.banners])
      }
    })
  },[])

  //console.log(data2)

  return  <>
            <div className="">
              <Upload/>
              <CarouselGood data={data2} url="./servicios"/>
              {
                /*<Carousel banner={banner} src_xs={banner1_xs}  title="" type="2" classNameTitle=" text-danger " text1="" text2="" src={banner1}/>* */
              }
              
            </div>
            <Title id={data&&data[0]} editable={data&&data[0]&&data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h1 mt-3 text-blue"/>
            <Title id={data&&data[1]} editable={data&&data[1]&&data[1].content} label={data[1]?htmlToReactParser.parse(data[1].content):false} className="text-center h3 mt-1 text-danger2"/>
            <Title id={data&&data[2]} editable={data&&data[2]&&data[2].content} label={data[2]?htmlToReactParser.parse(data[2].content):false} className="text-center p mt-1 text-dark"/>
            {
              /*<Biseccion/>*/
            }
            
            <SolicitudCreditoComponent/>
          </>
}

export default App
