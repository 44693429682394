import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get } from '../../helpers/api';


const limit = 20000000
const SolicitudCredito=()=>{
    const [inputs,setInputs]        =   React.useState({})
    useEffect(()=>{
        try {
            const getInit = async () => {
                // Obtener los parámetros de búsqueda de la URL actual
                const params        =   new URLSearchParams(window.location.search);
                const urlWithParams =   `api/formulario/datos-credito?${params.toString()}`;
                const response      =   await get(urlWithParams);
                setInputs(response&&response.data)
                //console.log(response);
            }

            getInit();

        } catch (error) {
            console.error('Error al verificar el inicio de sesión:', error);
        }
    },[])

    return  <Container>
                <Row className="justify-content-center mb-5">
                    <Col  className="pt-4 text-center"  xs={12} md={12}>
                        <h3>Solicitud de crédito</h3>
                    </Col>       
                    <Col  className="pt-4 text-start"  xs={12} md={12}>                        
                        <div className="container mt-2">
                            <div className='row'>
                                <div className='col-12 col-sm-4'>
                                    <h1>{inputs.name}</h1>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <h1> {inputs.surnames}</h1>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <h2>Cédula: <b>{inputs.identification}</b></h2>
                                </div>
                                <div className='col-12 col-sm-4 mb-5'>
                                    <h3>email: <b>{inputs.email}</b> </h3>
                                </div>
                                <div className='col-12 col-sm-4 mb-5'>
                                    <h3>Celular: <b>{inputs.cellphone}</b></h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-3 text-center border p-2'>
                                    <h3>Solicitud</h3>
                                    <a href={inputs.education_level} target='_blank' className='btn btn-primary'>
                                        Decargar
                                    </a>
                                </div>
                                <div className='col-12 col-sm-3 text-center border p-2'>
                                    <h3>Cédula</h3>
                                    <a href={inputs.img_identification} target='_blank' className='btn btn-primary'>
                                        Decargar
                                    </a>
                                </div>
                                <div className='col-12 col-sm-2 text-center border p-2'>
                                    <h3>Desprendible 1</h3>
                                    <a href={inputs.img_removable_payroll} target='_blank' className='btn btn-primary'>
                                        Decargar
                                    </a>
                                </div>
                                <div className='col-12 col-sm-2 text-center border p-2'>
                                    <h3>Desprendible 2</h3>
                                    <a href={inputs.business} target='_blank' className='btn btn-primary'>
                                        Decargar
                                    </a>
                                </div>
                                <div className='col-12 col-sm-2 text-center border p-2'>
                                    <h3>Desprendible 3</h3>
                                    <a href={inputs.position} target='_blank' className='btn btn-primary'>
                                        Decargar
                                    </a>
                                </div>
                            </div>
                        </div>                         
                    </Col>       
                </Row>
            </Container>        
}
export default SolicitudCredito;