import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';

import {
          Empty
        } from '../../convenios/todos'

const Biseccion=({img})=>{


  const   [data4,setData4]          =   React.useState([])  
  const   [data2,setData2]          =   React.useState([])  
  const   [data3,setData3]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    get("api/s/poliza_colectiva_2024").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData4(response.data.items)  
      }      
    })   
    get("api/s/2024_programandoweb_poliza_colectiva").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData2(response.data.items)  
      }      
    })    
    get("api/s/2024_programandoweb.net_poliza_colectiva").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData3(response.data.items)  
      }      
    })    
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <div className="row justify-content-center">
                  {
                    data4&&data4.map&&data4.map((row,key)=>{
                      return  <Col  className="text-start mb-3"  xs={12} md={4} key={key}>                        
                                <Empty data={row} />
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="523"  
                                                alias="poliza_colectiva_2024" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data4} 
                                                upload={true}
                                    />
                                  )
                                }
                              </Col>                  
                    })
                  }
                  <div className="col-7 pt-3 h4 text-danger2 text-start">Póliza colectiva de vida y hogar</div>
                </div>
                <div className="row justify-content-center">
                  {
                    data2&&data2.map&&data2.map((row,key)=>{
                      return  <Col  className="text-start mb-3"  xs={12} md={4} key={key}>                        
                                <Empty data={row} />
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="524"  
                                                alias="2024_programandoweb_poliza_colectiva" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data2} 
                                                upload={true}
                                    />
                                  )
                                }
                              </Col>                  
                    })
                  }
                  <div className="col-7 pt-3 h4 text-blue text-start">Póliza colectiva de vehículos axa colpatria</div>
                </div>
                <div className="row justify-content-center">
                  {
                      data3&&data3.map&&data3.map((row,key)=>{
                        return  <Col  className="text-start mb-3"  xs={12} md={4} key={key}>                        
                                  <Empty data={row} />
                                  {
                                    isUserLoggedIn&&(
                                      <Textarea   section_id="525"  
                                                  alias="2024_programandoweb.net_poliza_colectiva" 
                                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                  id={key} 
                                                  data={data3} 
                                                  upload={true}
                                      />
                                    )
                                  }
                                </Col>                  
                      })
                  }
                  <div className="col-7 pt-3 h4 text-danger2 text-start">Seguro de vida</div>
                </div>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
