import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { get } from '../../../helpers/api';
import Textarea from '../../common/Textarea';
import Leermas from '../Leermas';

const Biseccion=({img})=>{
  const   [data,setData]          =   React.useState([])  

  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    get("api/s/bienestra_gym_convenio2").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])


  return  <Container>
            <Row>

              <Col  className="text-center"  xs={12} md={3}>

              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                {
                  data&&data.map&&data[(data.length-1)]&&data[(data.length-1)].image&&(
                    <Image src={data[(data.length-1)].image||img} fluid/>    
                  )
                }                  
              </Col>
              <Col  className="text-start"  xs={12} md={4}>

                {
                  data&&data.map&&data.map((row,key)=>{
                    return  <div className="col-12 col-sm-6" key={key}>
                              {
                                isUserLoggedIn&&(
                                  <Textarea   section_id="522"  
                                              alias="bienestra_gym_convenio2" 
                                              sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                              id={key} 
                                              data={data} 
                                              upload={true}
                                  />
                                )
                              }
                            </div>
                  })
                }
                <Leermas>
                  {
                    data&&data.map&&data[(data.length-1)]&&data[(data.length-1)].content&&(
                      <div dangerouslySetInnerHTML={{ __html: data[(data.length-1)].content }} />                    
                    )
                  }                
                </Leermas>
                
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
