import  React, { useState }       from    'react';
import './css.css';
const Leermas = React.forwardRef((props, ref) => {
    const [status,setStatus]    =   useState(false)
    return (
      <React.Fragment>
        <div>
            <div className={status?"open":"close"}>
                {
                    props.children
                }                        
            </div>
            <div onClick={()=>setStatus(true)} className={status?"d-none":"btn btn-primary"}>Leer texto completo</div>
            <div onClick={()=>setStatus(false)} className={!status?"d-none":"btn btn-secondary"}>Cerrar</div>
        </div>
      </React.Fragment>
      
    );
  });
  
  
export default Leermas;