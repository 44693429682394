import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img10 from '../../../assets/images/bienestar/nosotros4-programandoweb.png'
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';
import {
  Empty,  
} from '../../convenios/todos'

const Biseccion=({img})=>{
  const   [data,setData]                        =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/bienestra_convenio_3").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Image src={img10} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <div className={"h4 text-danger2 text-start mt-5 pt-5"}>

                </div>
                <div className="row justify-content-center">
                  {
                    data&&data.map&&data.map((row,key)=>{
                      return  <div className="col-12 col-sm-6" key={key}>
                                <Empty data={row}/>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="518"  
                                                alias="bienestra_convenio" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={true}
                                    />
                                  )
                                }
                              </div>
                    })
                  }                  
                </div>
                {
                  isUserLoggedIn&&(
                    <div className='row'>
                      <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                        <Textarea section_id="519"  
                                  alias="bienestra_convenio_3" 
                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                  id={"new"} 
                                  data={{}} 
                                  upload={true}
                        /> Nuevos items 
                      </div>
                    </div>    
                  )
                } 
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
