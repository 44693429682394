import axios from 'axios';

export const get = async (endpoint) => {
  try {
    let headers = {};
    
    // Obtener el token de acceso del almacenamiento local
    const accessToken = localStorage.getItem('access_token');

    // Si hay un token, agregarlo a los encabezados
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken.replace(/"/g, '')}`;
      // La expresión regular /"/g se utiliza para eliminar todas las comillas en el token.
    }

    // Realizar la solicitud GET con axios
    const response = await axios.get(process.env.REACT_APP_BACKEND + endpoint, {
      headers,
    });

    // Verificar el estado de la respuesta antes de devolver los datos
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }

  } catch (error) {
    // Manejar errores de manera más específica según el tipo de error
    console.error("Error in 'get' function:", error);
    return error;
  }
}


export const post = async (endpoint, data, useFormData = false) => {
    try {
      let headers = {};

       // Consultar el token en el localStorage
      const accessToken = localStorage.getItem('access_token');

      // Si hay un token, incluirlo en las cabeceras
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken.replace(/"/g, '')}`;
        // La expresión regular /"/g se utiliza para eliminar todas las comillas en el token.
      }

      let response;
  
      if (useFormData) {
        // Si useFormData es verdadero, utiliza FormData
        const formData = new FormData();
  
        for (const key in data) {
          formData.append(key, data[key]);
        }
  
        response = await axios.post(process.env.REACT_APP_BACKEND + endpoint, formData, {
          headers,
        });
      } else {
        // Si useFormData es falso, envía datos JSON
        response = await axios.post(process.env.REACT_APP_BACKEND + endpoint, data, {
          headers,
        });
      }
  
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  

export const authenticatedPost = async (endpoint, data, useFormData = false) => {
  try {
    let headers = {};

    // Consultar el token en el localStorage
    const accessToken = localStorage.getItem('access_token');

    // Si hay un token, incluirlo en las cabeceras
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken.replace(/"/g, '')}`;
      // La expresión regular /"/g se utiliza para eliminar todas las comillas en el token.
    }

    let response;

    if (useFormData) {
      // Si useFormData es verdadero, utiliza FormData
      const formData = new FormData();

      for (const key in data) {
        formData.append(key, data[key]);
      }

      response = await axios.post(process.env.REACT_APP_BACKEND + endpoint, formData, {
        headers,
      });
    } else {
      // Si useFormData es falso, envía datos JSON
      response = await axios.post(process.env.REACT_APP_BACKEND + endpoint, data, {
        headers,
      });
    }

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
