import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const Biseccion=({img,title,paragraph})=>{
  return  <Container>
            <Row className="justify-content-center">
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={8} md={3}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-start"  xs={12} md={4}>
                <h3 className="text-blue">Misión</h3>
                <p>Contribuir con el bienestar social de los asociados y sus familias mediante la satisfacción de sus necesidades de manera oportuna y eficiente</p>
                <h3 className="text-blue">Visión</h3>
                <p>Ser una empresa del sector solidario líder en organización y desarrollo con capacidad para elevar la calidad de vida de los asociados y sus familias y contribuir con el desarrollo sostenible del país. </p>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
