import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const Biseccion=({img,title,paragraph})=>{
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <div className={title.className}>{title.label}</div>
                <div className={paragraph.className}>{paragraph.label}</div>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
