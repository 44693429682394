import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../assets/images/fondo_mutual.png'
import img2 from '../../assets/images/sede_social.png'
import img3 from '../../assets/images/educacion.png'
import img4 from '../../assets/images/entretenimiento.png'
import img5 from '../../assets/images/seguros.png'
import img6 from '../../assets/images/salud.png'
import img7 from '../../assets/images/turismo.png'

const items = [
                {
                  label:"Fondo Mutual",
                  img:img1,
                  url:"./fondo_mutual"
                },
                {
                  label:"Sede social",
                  img:img2,
                  url:"./sede_social"
                },
                {
                  label:"Educación",
                  img:img3,
                  url:"./educacion"
                },
                {
                  label:"Entretenimiento y Bienestar",
                  img:img4,
                  url:"./entretenimiento"
                },
                {
                  label:"Seguros",
                  img:img5,
                  url:"./seguros"
                },
                {
                  label:"Salud",
                  img:img6,
                  url:"./salud"
                },
                {
                  label:"Turismo",
                  img:img7,
                  url:"./turismo"
                }
              ]

const Biseccion=({img,title,subTitle,paragraph,btn})=>{
  return  <Container fluid className="position-relative">
            <Row className="p-0">
              <Image className="position-absolute bg-absolute p-0 m-0" src={img} fluid/>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Row className="p-5 mt-5 justify-content-md-center">
                  <Col className={title.className}  xs={12}>
                    {title.label}
                  </Col>
                  {items.map((row,key)=>{
                    return  <Col xs={6} md={3} className="mb-4 " key={key}>
                              <a href={row.url} className="lnk-free">
                                <Image className="" src={row.img} fluid/>
                                <div className="text-label">{row.label}</div>
                              </a>
                            </Col>
                  })}
                  <Col className={"text-start"}  xs={12}>
                    {
                      btn?  <a className={btn.className} href={btn.href}>
                              {btn.label}
                            </a>:false
                    }
                  </Col>
                </Row>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>

              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
