import React from 'react';
import Textarea from '../Textarea';
import {get} from '../../../helpers/api';

const Slider=()=>{

  const   [data,setData] =React.useState([])

  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)    
    get("api/s/banner_conocenos").then((response)=>{
      if (response&&response.data&&response.data.banner_conocenos&&response.data.banner_conocenos.map) {
        setData(response.data.banner_conocenos);
      }      
    })
  },[])

  console.log(data)

  return <>
            {data.map((row,key)=>{
                return    <div key={key} className="position-relative">
                            <div className="content-header position-absolute">
                              <Textarea section_id="507" 
                                        alias="banner_conocenos" 
                                        component={data[key]?<div dangerouslySetInnerHTML={{ __html:data[key].content}} />:false} 
                                        id={key} 
                                        data={data}
                                        upload={true}
                              />
                              <div dangerouslySetInnerHTML={{ __html: row.content }} />
                            </div>
                              <img  key={key} className="d-block w-100" src={row.image} alt="Programandoweb"/>

                          </div>

            })}
          </>
}

export default Slider
