import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/educacion/IMAGEN-EDUCACION.png'
import {get} from '../../../helpers/api';
import Textarea from '../../common/Textarea';
import { useEffect, useState } from 'react';

const Biseccion=({})=>{
  
  const   [data,setData]                        =   useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   useState(false);
  
  useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  useEffect(()=>{
    get("api/s/educacionsc2024").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })     
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Image src={img1} fluid/>    
              </Col>
              <Col  className="text-start"  xs={12} md={4}>
                {
                    data&&data.map&&data.map((row,key)=>{
                      return  <div className="col-12 col-sm-4" key={key}>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="528"  
                                                alias="educacionsc2024" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={false}
                                    />
                                  )
                                }
                              </div>
                    })
                }
                {
                  data&&data.map&&data[(data.length-1)]&&data[(data.length-1)].content&&(
                    <div dangerouslySetInnerHTML={{ __html: data[(data.length-1)].content }} />                    
                  )
                }  
                {
                  /*
                    <div className="text-justify">
                      {data[1] && data[1].content?data[1].content:false}
                    </div>
                    <br/>
                    <h5 className="h6 text-danger2"><b>Programas técnicos y laborales</b></h5>
                    <h4 className=" h5 text-blue">ESTUDIA LO QUE SIEMPRE HAS QUERIDO Y TE GUSTA EN CAMPOALTO</h4>
                    <ul>
                      <li>Auxiliar administrativo</li>
                      <li>Auxiliar en servicios farmacéuticos</li>
                      <li>Auxiliar en nóminas y presentaciónes sociales</li>
                      <li>Auxiliar contable</li>
                    </ul>  
                  
                  */
                }
                
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
