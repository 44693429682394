import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const MiModal = ({label, show, onHide, component, handleCloseModal, handleSaveChanges, btnDisabled}) => {
  return (
    <>
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {label}
                {component}                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSaveChanges} disabled={btnDisabled?true:false}>
                    Guardar Cambios
                </Button>
            </Modal.Footer>
        </Modal>

    </>
  );
};

export default MiModal;
