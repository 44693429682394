import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img0 from '../../../assets/images/seguros/nosotros3-programandoweb.png'
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';
import Title from '../../common/Title'
import { Empty } from '../../convenios/todos'


const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Biseccion=()=>{
  const   [data2,setData2]          =   React.useState([])  
  const   [data4,setData4]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/mascotas").then((response)=>{
      if (response.data && response.data.items) {
        setData2(response.data.items)
      }
    })
  },[])

  
  React.useEffect(()=>{
    get("api/s/jardines_programandoweb_ivoolve_2024").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData4(response.data.items)  
      }      
    })       
  },[])
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={8}>
                <div className='row text-center justify-content-center'>
                    {
                      data4&&data4.map&&data4.map((row,key)=>{
                        return  <Col  className="text-center mb-3"  xs={12} md={4} key={key}>                        
                                  <Empty data={row} />
                                  {
                                    isUserLoggedIn&&(
                                      <Textarea   section_id="527"  
                                                  alias="jardines_programandoweb_ivoolve_2024" 
                                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                  id={key} 
                                                  data={data4} 
                                                  upload={true}
                                      />
                                    )
                                  }
                                </Col>                  
                      })
                    }
                </div>  
                <div>
                  <Row className="justify-content-center">
                    <Col  className="text-start"  xs={9}>
                      {
                        data2&&data2[0]&&data2[0].content&&data2[0].content!==''&&(
                          <Title id={data2[0]} editable={data2[0].content} label={data2[0]?htmlToReactParser.parse(data2[0].content):false} className=" p "/>
                        )
                      }  
                    </Col>
                  </Row>
                </div>
                <Image src={img0} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
