import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';
import {
          Empty
        } from '../../convenios/todos'


const Biseccion=({img})=>{
  const   [data4,setData4]          =   React.useState([])  
  const   [data5,setData5]          =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    get("api/s/jardines_programandoweb_ivoolve").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData4(response.data.items)  
      }      
    })       

    get("api/s/jardines_programandoweb_ivoolve_2024_programando").then((response)=>{
      if (response&&response.data&&response.data.items) {
        console.log(response.data.items)
        setData5(response.data.items)  
      }      
    })       
  },[])


  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-start"  xs={12} md={4}>

                {
                  data5&&data5.map&&data5.map((row,key)=>{
                    return  <div className="col-12 col-sm-4" key={key}>
                              {
                                isUserLoggedIn&&(
                                  <Textarea   section_id="529"  
                                              alias="jardines_programandoweb_ivoolve_2024_programando" 
                                              sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                              id={key} 
                                              data={data5} 
                                              upload={false}
                                  />
                                )
                              }
                            </div>
                  })
                }
                {
                  data5&&data5.map&&data5[(data5.length-1)]&&data5[(data5.length-1)].content&&(
                    <div dangerouslySetInnerHTML={{ __html: data5[(data5.length-1)].content }} />                    
                  )
                } 

                <div>
                    <div className='row'>
                    {
                      data4&&data4.map&&data4.map((row,key)=>{
                        return  <Col  className="text-start mb-3"  xs={12} md={6} key={key}>                        
                                  <Empty data={row} />
                                  {
                                    isUserLoggedIn&&(
                                      <Textarea   section_id="526"  
                                                  alias="jardines_programandoweb_ivoolve" 
                                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                  id={key} 
                                                  data={data4} 
                                                  upload={true}
                                      />
                                    )
                                  }
                                </Col>                  
                      })
                    }
                    </div>                    
                </div>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
