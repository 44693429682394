import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import {get} from '../../../helpers/api';
import Textarea from '../../common/Textarea';
import {  Empty } from '../../convenios/todos'

const Biseccion=({img})=>{

  const   [data,setData]          =   React.useState([]) 

  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/salud_medicina_prepagada").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
              <Col  className="text-center"  xs={12} md={3}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-start"  xs={12} md={7}>
                <h3 className="h6 pt-5 text-blue">Medicina Prepagada</h3>
                <div className="row">
                  {
                    data&&data.map&&data.map((row,key)=>{
                      return  <div className="col-6" key={key}>
                                <div className="col-12">
                                  <Empty data={row} />                                 
                                </div>
                                <div className="col pt-4 h4 text-danger2 d-none">
                                  {row.content}                           
                                </div>
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="512"  
                                                alias="salud_medicina_prepagada" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={true}
                                    />
                                  )
                                }
                                
                              </div>                  
                    })
                  }                      
                </div>
                {
                  isUserLoggedIn&&(
                    <div className='row'>
                      <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                        <Textarea section_id="510"  
                                  alias="salud_medicina_complementaria" 
                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                  id={"new"} 
                                  data={{}} 
                                  upload={true}
                        /> Nuevos items 
                      </div>
                    </div>    
                  )
                }                
              </Col>
            </Row>
          </Container>
}

export default Biseccion
