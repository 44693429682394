import React from 'react';
import Carousel from '../common/Carousel'
import Title from '../common/Title'
import Biseccion from '../common/ahorros/Biseccion'
import Biseccion2 from '../common/ahorros/Biseccion2'
import banner1 from '../../assets/images/ahorros/banner-programandoweb.png'
import banner1_xs from '../../assets/images/ahorros/banner-programandoweb.png'

import {get} from '../../helpers/api';


const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{
  const   [data,setData] =React.useState([])
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      setData(response.data.items)
    })
  },[])
  return  <>
            <div className="">
              <Carousel src_xs={banner1_xs} title="Ahorros" type="2" classNameTitle=" text-danger " text1="" text2="" src={banner1}/>
            </div>
            {
              data&&data[0]&&(
                <Title id={data[0]} editable={data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h1 mt-3 text-blue"/>
              )
            }
            
            <Biseccion data={data}/>
            <Biseccion2 data={data}/>
          </>
}

export default App
