import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Textarea from '../../common/Textarea';
import {get} from '../../../helpers/api';
import Title from '../../common/Title'
import {
          Empty
        } from '../../convenios/todos'

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

        
const Biseccion=()=>{

  const   [data,setData]          =   React.useState([])  
  const   [data2,setData2]        =   React.useState([])  
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  
  React.useEffect(()=>{
    get("api/s/educacion_bachiller").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }      
    })    
  },[])

  React.useEffect(()=>{
    get("api/s/educacion_bachiller2").then((response)=>{
      if (response.data && response.data.items) {
        setData2(response.data.items)
      }
    })
  },[])

  return  <Container>
            <Row className="justify-content-center">
              <Col  className="text-start"  xs={9}>
                {
                  data&&data2[0]&&data2[0].content&&data2[0].content!==''&&(
                    <Title id={data2[0]} editable={data2[0].content} label={data2[0]?htmlToReactParser.parse(data2[0].content):false} className=" h1 mt-5 text-blue"/>
                  )
                }  
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col  className="text-start"  xs={8}>
                <Row className="justify-content-center">

                  {
                    data&&data.map&&data.map((row,key)=>{
                      return  <Col  className="text-start mb-3"  xs={12} md={3} key={key}>                        
                                <Empty data={row} />
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="516"  
                                                alias="educacion_bachiller" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data} 
                                                upload={true}
                                    />
                                  )
                                }
                              </Col>                  
                    })
                  }
                </Row>
                <Row>
                  <Col>
                    {
                      isUserLoggedIn&&(
                        <div className='row'>
                          <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                            <Textarea section_id="516"  
                                      alias="educacion_bachiller" 
                                      sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                      id={"new"} 
                                      data={{}} 
                                      upload={true}
                            /> Nuevos items 
                          </div>
                        </div>    
                      )
                    }     
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
}

export default Biseccion
