import React from 'react';
import Modal from '../common/Modal'
import logo from './logos/sura.png'
import logo_sanitas from './logos/logo_colsanitas.png'
import logo_SegurosBolivar from './logos/logo_SegurosBolivar.png'
import logo_JardinesDePaz from './logos/logo_JardinesDePaz.png'
import logo_Famisanar from './logos/logo_Famisanar.png'
import logo_Compensar from './logos/logo_Compensar.png'
import logo_Emi from './logos/logo_Emi .png'
import logo_Ami from './logos/ami.png'
import logo_UniAutonoma from './logos/logo_UniAutonoma.png'
import logo_Unad from './logos/logo_Unad.png'
import logo_Uninpahu from './logos/logo_Uninpahu .png'
import logo_UniLosLibertadores from './logos/logo_UniLosLibertadores.png'
import logo_UniMinuto from './logos/logo_UniMinuto .png'
import logo_Poli from './logos/logo_Poli.png'
import logo_Cun from './logos/logo_Cun.png'
import logo_smart from './logos/img13.png'
import logo_colsanitas from './logos/logo_colsanitas.png'
import logo_colsanitas2 from './logos/colsanitas.png'
import logo_emermedica from './logos/logo_emermedica.png'
import logo_segurosdelestado from './logos/logo_Cun.png'
import logo_predent from './logos/logo_predent.png'
import logo_maximavision from './logos/logo_maximavision.png'
import logo_innova from './logos/logo_innova.png'
import logo_optiglass from './logos/logo_optiglass.png'
import logo_opticauniversal from './logos/logo_opticauniversal .png'
import logo_oralty from './logos/logo_oralty.png'
import logo_dentisalud from './logos/logo_dentisalud .png'
import logo_clinicaodontologica from './logos/logo_clinicaodontologica.png'
import logo_odontocucuta from './logos/logo_odontocucuta .png'
import logo_viicolem from './logos/Recurso-25-9.png'
import logo_univer from './logos/Recurso-25-10.png'
import logo_vision from './logos/logo_vision.png'
import logo_sonria from './logos/logo_sonria.png'
import logo_spinningcenter from './logos/logo_spinningcenter.png'
import logo_botytech from './logos/logo_botytech.png'

import logo_campoalto from './logos/CAMPOALTO.png'
import logo_unireminton from './logos/UNIREMINGTON.png'

import logo_uninpahu from './logos/logo_uninpahu.png'
import logo_uniecci from './logos/logo_uniecci.png'
import logo_unicaribe from './logos/logo_unicaribe.png'
import logo_uniagustiana from './logos/logo_uniagustiana.png'
import logo_mariacano from './logos/logo_mariacano.png'
import logo_centro_colombo_americano from './logos/logo_centro_colombo_americano.png'
import logo_centro_electronico_idiomas from './logos/logo_centro_electronico_idiomas.png'
import logo_colegio_triangulo from './logos/logo_colegio_triangulo.png'
import logo_colpatria from './logos/colpatria.png'
import logo_planmascota from './logos/logo_planmascota.png'
import logo_opticagermana from './logos/logo_opticagermana.png'
import logo_odontoStatic from './logos/logo_odontoStatic.png'
import logo_vision_colombia from './logos/logo_vision_colombia.png'
import logo_cinmark from '../../assets/images/bienestar/img4.png'
import logo_cinecolombia from '../../assets/images/bienestar/img5.png'
import logo_cineprocinal from '../../assets/images/bienestar/img6.png'
import logo_cinepolis from '../../assets/images/bienestar/img10.png'
import logo_salitre from '../../assets/images/bienestar/img7.png'
import logo_royal from '../../assets/images/bienestar/img9.png'
import logo_olimpica from '../../assets/images/bienestar/logo_olimpica.png'
import logo_grandprix from '../../assets/images/bienestar/logo_grandprix.png'
import logo_mundoaventuras from '../../assets/images/bienestar/logo_mundoaventuras.png'
import logo_medisanitas from './logos/medisanitas.png'


import logo_ossa from '../../assets/images/turismo/img1.png'
import logo_viajar from '../../assets/images/turismo/img2.png'
import logo_globaltours from '../../assets/images/turismo/img3.png'

import logo_combarranquilla from '../../assets/images/turismo/img5.png'
import logo_confacor from '../../assets/images/turismo/img6.png'
import logo_confama from '../../assets/images/turismo/img7.png'
import logo_confenalco from '../../assets/images/turismo/img8.png'




import whatsapp from '../../assets/images/icons/whatsapp.png'

import {get} from '../../helpers/api';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Whatsapp=()=>{
  return  <a className="row" href="https://wa.me/573134061514" target="_blank">
            <div className="col-3 text-center">
              <img src={whatsapp} className="img-fluid"/>
            </div>
            <div className="col">
              <b className="h5">313 4061514</b>
            </div>
          </a>
}

export const Confenalco=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Confenalco").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_confenalco} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_confenalco} alt="programandoweb"  className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Confama=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Confama").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_confama} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_confama} alt="programandoweb"  className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Confacor=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Confacor").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_confacor} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_confacor} alt="programandoweb"  className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const ComBarranquilla=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/ComBarranquilla").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_combarranquilla} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_combarranquilla} alt="programandoweb"  className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Viajar=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Viajar").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_viajar} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_viajar} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Globaltours=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Globaltours").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_globaltours} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_globaltours} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Ossa=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Ossa").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_ossa} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_ossa} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}



export const Medisanitas=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Medisanitas").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_medisanitas} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_medisanitas} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Salitre=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Salitre").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_salitre} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_salitre} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const MundoAventuras=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/MundoAventuras").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_mundoaventuras} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_mundoaventuras} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Grandprix=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Grandprix").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_grandprix} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_grandprix} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Olimpica=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Olimpica").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_olimpica} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_olimpica} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Royal=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Royal").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_royal} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_royal} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const CinePolis=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CinePolis").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_cinepolis} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_cinepolis} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const CineProcinal=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CineProcinal").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_cineprocinal} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_cineprocinal} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const CineColombia=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CineColombia").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_cinecolombia} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_cinecolombia} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Cinemark=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Cinemark").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_cinmark} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_cinmark} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const VisionColombia=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/VisionColombia").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_vision_colombia} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_vision_colombia} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const OdontoStatic=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/OdontoStatic").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_odontoStatic} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_odontoStatic} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const OpticaGermana=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/OpticaGermana").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_opticagermana} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_opticagermana} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const PlanMascota=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/PlanMascota").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_planmascota} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_planmascota} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Axa=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Axa").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_colpatria} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_colpatria} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const ColegioTriangulo=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/ColegioTriangulo").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_colegio_triangulo} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_colegio_triangulo} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const CentroElectronicoIdiomas=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CentroElectronicoIdiomas").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_centro_electronico_idiomas} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_centro_electronico_idiomas} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const CentroColomboAmericano=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CentroColomboAmericano").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_centro_colombo_americano} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_centro_colombo_americano} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const UniMariaCano=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/UniMariaCano").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_mariacano} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_mariacano} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const UniAgustiana=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/UniAgustiana").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_uniagustiana} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_uniagustiana} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}



export const UniCaribe=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/UniCaribe").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_unicaribe} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_unicaribe} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}



export const UninEcci=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/UninEcci").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_uniecci} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_uniecci} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const UninPahu=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/UninPahu").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_uninpahu} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_uninpahu} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}

export const Unireminton=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Unireminton").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_unireminton} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_unireminton} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const CampoAlto=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/CampoAlto").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_campoalto} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_campoalto} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Sura=({src,className})=>{
  const   [open,setOpen]    = React.useState(false)
  const   [data,setData]    = React.useState({})

  React.useEffect(()=>{
    get("api/s2/Sura").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])



  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}



export const Sanitas=({src,className})=>{
  const [open,setOpen]  = React.useState(false)

  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Sanitas").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])

  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_sanitas} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_sanitas} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}




export const SegurosBolivar=({src,className})=>{
  const [open,setOpen]  = React.useState(false)

  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/SegurosBolivar").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])

  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_SegurosBolivar} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_SegurosBolivar} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const JardinesDePaz=({src,className})=>{
  const [open,setOpen]  = React.useState(false)

  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/JardinesDePaz").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])

  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_JardinesDePaz} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={src||logo_JardinesDePaz} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Famisanar=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Famisanar").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Famisanar} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Famisanar} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const Compensar=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Compensar").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={src||logo_Compensar} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Compensar} alt="programandoweb"  className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}



export const Emi=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Emi").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Emi} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Emi} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const Ami=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Ami").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Ami} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Ami} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const UniAutonoma=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/UniAutonoma").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_UniAutonoma} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
              {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_UniAutonoma} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Unad=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Unad").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Unad} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Unad} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Uninpahu=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Uninpahu").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Uninpahu} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Uninpahu} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const UniLosLibertadores=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/UniLosLibertadores").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_UniLosLibertadores} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_UniLosLibertadores} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const UniMinuto=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/UniMinuto").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_UniMinuto} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_UniMinuto} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Poli=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Poli").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Poli} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Poli} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const Cun=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Cun").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_Cun} alt="programandoweb" className="img-fluid"/>
                <div className="row">
                  <div className="col-2">
                    <img src={whatsapp} className="img-fluid"/>
                  </div>
                  <div className="col">
                    <small><b>313 4061514</b></small>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_Cun} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const AcademiaSmart=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/AcademiaSmart").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_smart} alt="programandoweb" className="img-fluid"/>
                <div className="row">
                  <div className="col-2">
                    <img src={whatsapp} className="img-fluid"/>
                  </div>
                  <div className="col">
                    <small><b>313 4061514</b></small>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_smart} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const Colsanitas=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Colsanitas").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_colsanitas2} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_colsanitas2} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}


export const Emermedica=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Emermedica").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_emermedica} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_emermedica} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const SegurosDelEstado=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/SegurosDelEstado").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_segurosdelestado} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_sanitas} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Predent=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Predent").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_predent} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}


              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_predent} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Univer=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Univer").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_univer} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_univer} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const MaximaMVVision=({className})=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/MaximaMVVision").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_maximavision} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_maximavision} alt="programandoweb" className={className +" cursor-pointer " || "img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Innova=()=>{
  const [open,setOpen]  = React.useState(false)
  const   [data,setData]    = React.useState({})
  React.useEffect(()=>{
    get("api/s2/Innova").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_innova} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_innova} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Optiglass=({className})=>{
  const [open,setOpen]    =   React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Optiglass").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_optiglass} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_optiglass} alt="programandoweb" className={className||"img-fluid cursor-pointer"}  onClick={()=>{setOpen(true)}}/>
          </>
}




export const Optiuniversal=({className})=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Optiuniversal").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_opticauniversal} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_opticauniversal} alt="programandoweb" className={className||"img-fluid cursor-pointer"}  onClick={()=>{setOpen(true)}}/>
          </>
}



export const Oralty=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Oralty").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_oralty} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_oralty} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}




export const Sonria=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Sonria").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_sonria} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_sonria} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}




export const Dentisalud=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Dentisalud").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_dentisalud} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_dentisalud} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}




export const ClinicaOdontologica=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/ClinicaOdontologica").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_clinicaodontologica} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_clinicaodontologica} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}




export const OdontoCucuta=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/OdontoCucuta").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_odontocucuta} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_odontocucuta} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Viicolem=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Viicolem").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_viicolem} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_viicolem} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const Vision=()=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/Vision").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_vision} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_vision} alt="programandoweb" className="img-fluid cursor-pointer" onClick={()=>{setOpen(true)}}/>
          </>
}



export const BodyTech=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/BodyTech").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_botytech} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_botytech} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const SpinningCenterGym=({src,className})=>{
  const [open,setOpen]  = React.useState(false)
  const [data,setData]    =   React.useState({})
  React.useEffect(()=>{
    get("api/s2/SpinningCenterGym").then((response)=>{
      if (response.data && response.data.items && response.data.items[0]) {
        setData(response.data.items[0])
      }
    })
  },[])
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-4">
                <img src={logo_spinningcenter} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {htmlToReactParser.parse(data.content)}
              </div>
            </div>
  }
  return  <>
            {open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false}
            <img src={logo_spinningcenter} alt="programandoweb" className={className||"img-fluid cursor-pointer"} onClick={()=>{setOpen(true)}}/>
          </>
}


export const Empty=({data,className})=>{
  const   [open,setOpen]  = React.useState(false)
  const Html=()=>{
    return  <div className="row">
              <div className="col-12 col-sm-3">
                <img src={data.image} alt="programandoweb" className="img-fluid"/>
                <Whatsapp/>
              </div>
              <div className="col-12 col-sm-8 f-size-12 simula_parrafos">
                {
                  htmlToReactParser.parse(data.content)
                }
              </div>
            </div>
  }
  return  <>
            {
              
              open?<Modal openPQR={open} title="Alianzas comerciales"setOpenPQR={setOpen} Component={Html}/>:false
              
            }
            <img src={data.image} alt="programandoweb"  className={className||" w-50-200 "} onClick={()=>{setOpen(true)}}/>
          </>
}