import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Textarea from '../../common/Textarea';

const Biseccion=({data})=>{
  const [isUserLoggedIn, setIsUserLoggedIn]   =       React.useState(false);
  React.useEffect(() => {        
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
}, []);
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
              <Col  className="text-center"  xs={12} md={10}>
                <div className="row justify-content-center">
                  {
                    data.map((row,key)=>{
                      if (row.status===-1&&!isUserLoggedIn) {
                        //console.log(row)
                        return;
                      }
                      
                      return    <div key={key} className="col-12 col-sm-3">
                                  <div className="imageWrapper">
                                    <div className="cornerLink">
                                      <Textarea section_id="504" 
                                                alias="section_conocenos" 
                                                component={data[key]?<div dangerouslySetInnerHTML={{ __html:data[key].content}} />:false} 
                                                id={key} 
                                                data={data}
                                                upload={true}
                                      />
                                      <div dangerouslySetInnerHTML={{ __html: row.content }} />
                                    </div>
                                    <Image src={row.image} className="img-fluid mb-3"/>
                                  </div>
                                </div>
                    })
                  }
                </div>
              </Col>

            </Row>

          </Container>
}

export default Biseccion
