import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img from '../../../assets/images/libreinversion/drone.png'

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Biseccion=({data})=>{
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>
              </Col>
              <Col  className=""  xs={12} md={4}>
                <p className="mt-5 pt-5 ">
                  {data[1]?htmlToReactParser.parse(data[1].content):false}
                </p>
              </Col>
              <Col  className="text-start"  xs={12} md={6}>
                <Image src={img} fluid/>
              </Col>
            </Row>
          </Container>
}

export default Biseccion
