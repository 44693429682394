import Carousel from '../common/CarouselNew'
import Title from '../common/Title'
import Biseccion from '../common/servicios/Biseccion'
import banner1 from '../../assets/images/servicios/banner-programandoweb.png'
import React from 'react';
import {get} from '../../helpers/api';
import Upload from '../common/Upload';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{
  const   [data,setData] =React.useState([])
  const   [banner,setBanner]  = React.useState(false)
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      if (response&&response.data&&response.data.banners&&response.data.banners) {
        setBanner(response.data.banners)          
      }
    })
  },[])
  return  <>
            <div className="">
              <Upload/>
              <Carousel banner={banner} title="Nuestros servicios" classNameTitle=" text-danger text-200" text1="" text2="" src={banner1}/>
            </div>
            {
              data&&data[0]&&(
                <Title id={data[0]} editable={data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false}  className="text-center h1 mt-3 text-blue"/>
              )
            }
            
            <Biseccion/>
          </>
}

export default App
