
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import { post } from '../../../helpers/api';


const limit = 20000000
const SolicitudCredito=()=>{
    //const [inputs,setInputs]          =   React.useState({})
    const [inputs, setInputs]           =   React.useState({
        name: '',
        surnames: '',
        identification: '',
        email: '',
        cellphone: '',
        neighborhood: '',
        city: '',
        department: '',
        valor_requerido: " "
    });
    const [file,setFile]            =   React.useState(false)
    const [isLogin,setIsLogin]      =   React.useState(false)
    //const [activeTab, setActiveTab] =   React.useState('longer-tab'); //longer-tab //profile
    const [activeTab, setActiveTab] =   React.useState('profile'); 
    const user                      =   localStorage.getItem('user');

    useEffect(()=>{
        try {
            if (user) {
                setInputs(JSON.parse(user))
                setIsLogin(true);
            }
        } catch (error) {
            console.error('Error al verificar el inicio de sesión:', error);
        }
    },[user])
    
    const onChange=(e,number)=>{
        const input     =   e.target;
        
        if (number) {

            const currentValue = input.value;
            // Verificar si el valor ingresado es un número
            if (isNaN(currentValue)|| currentValue.includes('.')) {
                // Si el valor ingresado no es un número, prevenir la acción por defecto y salir de la función
                e.preventDefault();
                return;
            }
        }
        
        const { name, value }   =   e.target;
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: value
        }));
        //localStorage.setItem('user', JSON.stringify(inputs));
    }

    const handleChangeFile = async (e) => {
        try {
            const { name }  =   e.target;
            const file      =   e.target.files[0]; // Obtener el primer archivo seleccionado
    
            if (file) {
                if (file.size>=limit) {
                    alert("Imagen supera límite permitido de ("+limit/1000000+") MB")
                }  
                const uri       =   "api/comment/uploadfile";
                //context.post(uri,{images:e.files[0],id:id,name:name},false,true)
                const response  =   await post(uri,    {  
                                                            images: file,
                                                            grupo:"solicitudes",
                                                            id:"solicitudes" ,
                                                            name:"solicitudes"  
                                                        }, true).then(response=>{
                    setInputs((prev) => {
                        return {
                            ...prev,
                            [name]: response.data
                        };
                    });
                    setActiveTab('longer-tab');
                })  
            }

        } catch (error) {
            console.error('Error al manejar el archivo:', error);
        }
    };
    
    const handleOnSubmit=()=>{

        if(inputs.email!==inputs.email2){
            alert("Los emails no coinciden, por favor verifícalos")
            return ;
        }else{
            delete(inputs.email2)
        }
        

        post("api/formulario/credit_application",{ ...inputs }, true).then(response=>{
            if (response.data && response.data.user) {
                // Almacena la información del usuario en localStorage
                localStorage.setItem('user', JSON.stringify({...inputs}));
                localStorage.setItem('access_token', JSON.stringify(response.data.access_token));                
                setIsLogin(true)
                setActiveTab('home');
            }           
        })  

    }


    const handleOnEnd=()=>{

        try {
            let send    =   {...inputs}
            send.education_level                =   inputs.education_level[(inputs.education_level.length-1)].file;
            send.img_identification             =   inputs.img_identification[(inputs.img_identification.length-1)].file;
            send.img_removable_payroll          =   inputs.img_removable_payroll[(inputs.img_removable_payroll.length-1)].file;            
            send.business                       =   inputs.business[(inputs.business.length-1)].file;
            send.position                       =   inputs.position[(inputs.position.length-1)].file;

            if (send.img_identification&&send.img_removable_payroll&&send.business&&send.position) {
                post("api/formulario/credit_application_end",{ ...send }, true).then(response=>{
                    if (response.data && response.data==='Solicitud culminada con éxito') {
                        // Almacena la información del usuario en localStorage
                        localStorage.removeItem('user');
                        localStorage.removeItem('access_token');
                        setIsLogin(false);
                        setActiveTab('contact');
                    }           
                })     
            }                        
            
                
        } catch (error) {
            alert(error)
            console.log(error)    
        }       
        

    }

    

    return      <Row className="justify-content-center mb-5">
                    <Col  className="pt-4 text-center"  xs={12} md={12}>
                        <h3>Solicitud de crédito</h3>
                    </Col>       
                    <Col  className="pt-4 text-center"  xs={12} md={12}>
                        
                            <div className="container mt-2">
                                <Tabs
                                        activeKey={activeTab}
                                        id="fill-tab-example"
                                        className="mb-3"
                                        fill
                                >       <Tab eventKey="profile" title="Información personal" >
                                            <div style={{minHeight:"34vh"}}>
                                                <Alert variant={"primary"}>
                                                    Primero llena unos datos básicos para identificarte en el proceso de nuestra plataforma
                                                </Alert> 
                                                <Row >
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.name}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Nombres"
                                                        name="name"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.surnames}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Apellidos"
                                                        name="surnames"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.identification}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Cédula de Ciudadanía"
                                                        name="identification"
                                                        onChange={(e)=>onChange(e,"number")}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.email2}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Correo electrónico"
                                                        name="email2"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.email}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Confirmar correo electrónico"
                                                        name="email"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={4}>
                                                    <input
                                                        value={inputs.cellphone}
                                                        className="form-control"
                                                        placeholder="Celular"
                                                        name="cellphone"
                                                        onChange={(e)=>onChange(e,"number")}
                                                        required
                                                    />
                                                </Col>
                                                {
                                                    /*
                                                        <Col className="pt-4 text-center" xs={12} md={3}>
                                                    <input
                                                        value={inputs.neighborhood}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Barrio"
                                                        name="neighborhood"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={3}>
                                                    <input
                                                        value={inputs.city}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Ciudad"
                                                        name="city"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="pt-4 text-center" xs={12} md={3}>
                                                    <input
                                                        value={inputs.department}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Departamento"
                                                        name="department"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>   
                                                 <Col className="pt-4 text-center" xs={12} md={3}>
                                                    <input
                                                        value={inputs.valor_requerido}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Monto a solicitar"
                                                        name="valor_requerido"
                                                        onChange={onChange}
                                                        required
                                                    />
                                                </Col>
                                                    * */
                                                }                                                
                                               


                                                    <Col  className="pt-4 text-end"  xs={12} >
                                                        {
                                                            isLogin&&(
                                                                <button className='btn btn-primary btn-block btn-lg' onClick={handleOnSubmit} disabled={!isLogin?true:false}>
                                                                    Guardar y continuar...
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            !isLogin&&(
                                                                <button     onClick={handleOnSubmit}
                                                                            className='btn btn-primary btn-block btn-lg' 
                                                                            disabled={
                                                                                inputs.email&&
                                                                                inputs.email===inputs.email2&&
                                                                                inputs.cellphone&&
                                                                                inputs.identification&&
                                                                                inputs.surnames&&
                                                                                inputs.name?false:true
                                                                            }
                                                                >
                                                                    Registrar
                                                                </button>
                                                            )
                                                        }
                                                        
                                                    </Col>            
                                                </Row> 
                                            </div>                                                
                                        </Tab>                         
                                        <Tab eventKey="home" title="Diligenciar formato solicitud" disabled={isLogin?false:true}>
                                            <div style={{minHeight:"34vh"}}>
                                                <Alert variant={"primary"}>
                                                    Sírvase descargar el formato, imprimirlo, diligenciarlo y 
                                                    posteriormente escanearlo para subirlo al nuestra plataforma firmado por usted.
                                                </Alert> 
                                                {
                                                    /*
                                                        <div>
                                                            <input  defaultValue={inputs.valor_requerido} 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Monto a solicitar" 
                                                                    name="valor_requerido" 
                                                                    onChange={onChange} required/>
                                                        </div>
                                                    * */
                                                }
                                                
                                                {
                                                    inputs&&(
                                                        <div>                                
                                                            <a href="https://api.fesol.com.co/public/crediya_libranza.pdf" target='_blank' className='btn btn-primary mt-4 me-2 btn-lg'>
                                                                Decargar Formato
                                                            </a>
                                                            <div className={ 'btn btn-secondary mt-4 btn-lg position-relative' }>
                                                                <input  type='file' 
                                                                        name="education_level"
                                                                        accept="application/pdf" 
                                                                        onChange={handleChangeFile} 
                                                                        style={{    
                                                                                    position:"absolute", 
                                                                                    top:0,
                                                                                    right:0, 
                                                                                    width:"100%", 
                                                                                    height:"100%",
                                                                                    opacity:0,
                                                                                }}
                                                                />
                                                                Subir formato diligenciado y firmado en PDF
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </Tab>
                                        <Tab eventKey="longer-tab" title="Subir requisitos"  disabled={isLogin?false:true}> 
                                            <div style={{minHeight:"34vh"}} >
                                                <Alert variant={"primary"} className='text-start'>
                                                    Debes subir los archivos en formato PDF:
                                                    <ul>
                                                        <li>Cédula de ciudadanía ambos lados</li>
                                                        <li>3 últimos desprendibles de pago</li>                                                    
                                                    </ul>
                                                </Alert> 
                                                <Row >
                                                    <Col  className="pt-4 text-center"  xs={12} md={3}>
                                                        <div className='card p-5 position-relative cursor-pointer' style={{cursor:"pointer",border:(inputs.img_identification?"solid 5px #333":"")}}>
                                                            <label>Cédula por ambos lados</label>
                                                            <input  type='file' 
                                                                    name="img_identification"
                                                                    accept="application/pdf" 
                                                                    onChange={handleChangeFile} 
                                                                    style={{    
                                                                                position:"absolute", 
                                                                                top:0,
                                                                                right:0, 
                                                                                width:"100%", 
                                                                                height:"100%",
                                                                                opacity:0,
                                                                            }}
                                                            />
                                                        </div>
                                                    </Col>            
                                                    <Col  className="pt-4 text-center"  xs={12} md={3}>
                                                        <div className='card p-5 position-relative cursor-pointer' style={{cursor:"pointer",border:(inputs.img_removable_payroll?"solid 5px #333":"")}}>
                                                            <label>Primer comprobante de nómina</label>
                                                            <input  type='file' 
                                                                    name="img_removable_payroll"
                                                                    accept="application/pdf" 
                                                                    onChange={handleChangeFile} 
                                                                    style={{    
                                                                                position:"absolute", 
                                                                                top:0,
                                                                                right:0, 
                                                                                width:"100%", 
                                                                                height:"100%",
                                                                                opacity:0,
                                                                            }}
                                                            />
                                                        </div>
                                                    </Col> 
                                                    <Col  className="pt-4 text-center"  xs={12} md={3}>
                                                        <div className='card p-5 position-relative cursor-pointer' style={{cursor:"pointer",border:(inputs.business?"solid 5px #333":"")}}>
                                                            <label>Segundo comprobante de nómina</label>
                                                            <input  type='file' 
                                                                    name="business"
                                                                    accept="application/pdf" 
                                                                    onChange={handleChangeFile} 
                                                                    style={{    
                                                                                position:"absolute", 
                                                                                top:0,
                                                                                right:0, 
                                                                                width:"100%", 
                                                                                height:"100%",
                                                                                opacity:0,
                                                                            }}
                                                            />
                                                        </div>
                                                    </Col> 
                                                    <Col  className="pt-4 text-center"  xs={12} md={3}>
                                                        <div className='card p-5 position-relative cursor-pointer' style={{cursor:"pointer",border:(inputs.position?"solid 5px #333":"")}}>
                                                            <label>Tercer comprobante de nómina</label>
                                                            <input  type='file' 
                                                                    name="position"
                                                                    accept="application/pdf" 
                                                                    onChange={handleChangeFile} 
                                                                    style={{    
                                                                                position:"absolute", 
                                                                                top:0,
                                                                                right:0, 
                                                                                width:"100%", 
                                                                                height:"100%",
                                                                                opacity:0,
                                                                            }}
                                                            />
                                                        </div>
                                                    </Col> 
                                                    <Col className="pt-4 text-end"  xs={12} >
                                                        {
                                                            isLogin&&
                                                            inputs.img_identification&&
                                                            inputs.img_removable_payroll&&
                                                            inputs.business&&
                                                            inputs.position&& (
                                                                <button className='btn btn-primary btn-block btn-lg' 
                                                                        disabled={!isLogin?true:false} onClick={()=>{handleOnEnd();}}>
                                                                    Guardar y continuar...
                                                                </button>
                                                            )
                                                        }
                                                    </Col>           
                                                </Row> 
                                            </div>                                                
                                        </Tab>
                                        <Tab eventKey="contact" title="Final" disabled={isLogin?false:true}>
                                            <Alert variant={"primary"}>
                                                Su solicitud se encuentra en trámite. Una vez que analicemos la información que nos suministró, nos pondremos en contacto con usted, en un plazo no mayor a 3 días hábiles.
                                            </Alert> 
                                        </Tab>
                                    
                                </Tabs>                               
                            </div> 
                        
                    </Col>       
                </Row>
            
}
export default SolicitudCredito;