
import Carousel from 'react-bootstrap/Carousel';
const Slider=({data})=>{
  if (!data) {
    data=[]
  }
  return <Carousel>
            {data.map((row,key)=>{
                return <Carousel.Item key={key}>
                          <a href={row.url} target="_blank">
                            <img
                                className="d-none d-sm-block w-100"
                                src={row.src}
                                alt="Programandoweb"
                            />
                            <img
                                className="d-block d-sm-none w-100"
                                src={row.src_mobile||row.src}
                                alt="Programandoweb"
                            />
                            <Carousel.Caption className="d-none2">
                              <h3>{row.title}</h3>
                              <p>{row.label}</p>
                            </Carousel.Caption>
                          </a>
                        </Carousel.Item>
            })}
          </Carousel>
}

export default Slider
