import React from 'react';
import Carousel from '../common/CarouselNew'
import Title from '../common/Title'
import Biseccion from '../common/bienestar/Biseccion'
import Biseccion2 from '../common/bienestar/Biseccion2'
import Biseccion3 from '../common/bienestar/Biseccion3'
import Biseccion4 from '../common/bienestar/Biseccion4'
import img1 from '../../assets/images/bienestar/nosotros-programandoweb.png'
import img2 from '../../assets/images/bienestar/nosotros-2-programandoweb.png'
import banner1 from '../../assets/images/bienestar/banner-programandoweb.png'
import banner1_xs from '../../assets/images/bienestar/banner-programandoweb.png'

import {get} from '../../helpers/api';
import Upload from '../common/Upload';


const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const items   =   [
                    {
                      label:"",
                      title:"",
                      src:banner1,
                    },
                  ]
const App=()=>{
  const   [data,setData] =React.useState([])
  const   [banner,setBanner]  = React.useState(false)
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      if (response&&response.data&&response.data.banners&&response.data.banners) {
        setBanner(response.data.banners)          
      }
    })
  },[])
  return  <>
            <div className="">
              <Upload/>
              <Carousel banner={banner} src_xs={banner1_xs} title="Bienestar"  classNameTitle=" text-danger " text1="" text2="" src={banner1}/>
            </div>
            {
              data&&data[0]&&data[0].content&&(
                <Title id={data[0]} editable={data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h2 mt-3 mb-4 text-blue"/>                
              )
            }

            {
              data&&data[1]&&data[1].content&&(
                <Title id={data[1]} editable={data[1].content} label={data[1]?htmlToReactParser.parse(data[1].content):false} className="text-center h6 mb-4 "/>
              )
            }   
            
            
            <Biseccion  img={img1}
                        title={{label:"Aquí encontrarás aulas", className:"h5 text-blue text-start "}}
                        paragraph={{  label:data[2]?htmlToReactParser.parse(data[2].content):false, className:"p text-start mb-4"}}/>
            {
              data&&data[3]&&data[3].content&&(
                <Title id={data[3]} editable={data[3].content} label={data[3]?htmlToReactParser.parse(data[3].content):false} className="text-center h2 mt-3 text-blue"/>
              )
            }
            
            {
              data&&data[4]&&data[4].content&&(
                <Title id={data[4]} editable={data[4].content} label={data[4]?htmlToReactParser.parse(data[4].content):false} className="text-center h6 mb-4 "/>
              )
            }
            
            <Biseccion2 img={img2}
                        title={{label:"Aquí encontrarás aulas", className:"h4 text-blue text-start mt-5 pt-5"}}
                        paragraph={{  label:"Se Ofrecen talleres en competencias blandas que motíven el éxito laboral, de acuerdo a la necesidad y requerimiento empresarial", className:"p text-start mb-4"}}/>
                        
            <Title label="Convenios a nivel Nacional" className="text-center h2 mt-3 text-danger2"/>
            {
              data&&data[5]&&data[5].content&&(
                <Title id={data[5]} editable={data[5].content} label={data[5]?htmlToReactParser.parse(data[5].content):false} className="text-center h6 mb-4 "/>
              )
            }
            <Biseccion3  data={data}/>
            <Biseccion4  data={data}/>
          </>
}

export default App
