import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from '../common/Carousel'
import CarouselGood from '../common/CarouselGood';
import Title from '../common/Title'
import CardContent from '../common/CardContent'
import Biseccion from '../common/Biseccion'
import Beneficies from '../common/Beneficies'
import BalanceSocial from '../common/BalanceSocial'
import Clients from '../common/Clients'
import Image from 'react-bootstrap/Image'

import Modal from '../common/Modal';


import crediya from '../../assets/images/crediya.png'
import libreInversion from '../../assets/images/libre-inversion.png'
import educacion from '../../assets/images/educacion/IMAGEN-EDUCACION.png'
import metas from '../../assets/images/metas.png'
import vivienda from '../../assets/images/vivienda.png'
import bannerServicios from '../../assets/images/banner-servicios-programandoweb.png'

import banner2 from '../../assets/images/banner2-programandoweb.png'
import banner2_xs from '../../assets/images/mobile/banner2-programandoweb.png'

import banner3 from '../../assets/images/mano-programandoweb.png'
import banner3_xs from '../../assets/images/mobile/mano-programandoweb.png'
import banner4 from '../../assets/images/audifonos-programandoweb.png'
import banner4_xs from '../../assets/images/mobile/audifonos-programandoweb.png'


import google from '../../assets/images/icons/google-play.png'
import apple from '../../assets/images/icons/app-store.png'


import {get} from '../../helpers/api';
import Upload from '../common/Upload/MultilpleBanners';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Htmls=()=>{
  return  <div className="row justify-content-center">
            <div  className="col-12 col-sm-3">
              <a href="https://play.google.com/store/apps/details?id=com.cyf.soluciones&hl=es_CO&gl=US&pli=1" target="_blank">
                <img src={google} alt="programandoweb" className="img-fluid"/>
              </a>
            </div>
            <div  className="col-12 col-sm-3">
              <a href="https://apps.apple.com/co/app/fesol-app/id1495441848" target="_blank">
                <img src={apple} alt="programandoweb" className="img-fluid"/>
              </a>
            </div>
          </div>
}


const data2_=[
              // {
              //   title:"",
              //   label:"",
              //   src:banner21,
              //   url:"https://api.fesol.com.co/public/DELEGADOS_PAGINA.pdf"
              // },
              // {
              //   title:"",
              //   label:"",
              //   src:banner1,
              //   url:"#"
              // }
            ]

const App=()=>{

  const   [data,setData] =React.useState([])
  const   [data2,setData2] =React.useState(data2_)

  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"/api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response.data && response.data.items) {
        setData(response.data.items)
      }
    })
  },[])


  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/banners_public":"/api/banners_public"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response.data && response.data.items) {
        setData2(response.data.items)
      }
    })
  },[])

  const [open,setOpen]  =   React.useState(false)

  return  <>
            <Modal openPQR={open} setOpenPQR={setOpen} Component={Htmls} title="Descarga APP"/>
            <div className="d-none-">
              <Upload/>
              <CarouselGood data={data2} url="./servicios"/>
              {
                /*
                  <Carousel title="Afiliate a Fesol y vive experiencias increíbles" text1="" text2="" src={banner2} src_xs={banner2_xs} url="https://pwa.fesol.com.co/web/formulario/afiliate" classNameBtn="btn btn-danger text-white btn-oficina col-6 p-1"/>  
                * */
              }

              <Carousel text1="" text2="" src={"https://api.fesol.com.co/public/uploads/programandoweb/programandoweb-pc.png"} src_xs={"https://api.fesol.com.co/public/uploads/programandoweb/programandoweb-movil.png"} url="https://pwa.fesol.com.co/web/formulario/afiliate" classNameBtn="btn btn-danger text-white btn-oficina col-6 p-1"/>
              
            </div>
            {
              data&&data[0]&&data[0].content&&data[0].content!==''&&(
                <Title id={data[0]} editable={data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h2 mt-3"/>
              )
            }            
            {
              data&&data[1]&&data[1].content&&data[1].content!==''&&(
                <Title id={data[1]} editable={data[1].content} label={data[1]?htmlToReactParser.parse(data[1].content):false} className="text-center h6 mb-4"/>
              )
            }
            
            <Container>
              <Row className="text-center cardContent">
                <CardContent img={crediya} title={"Crediyá"} xs={12} md={3} classNameTitle={"title-red h3 font-weight-bold"} btn={{label:"Ver más", className:"btn btn-danger btn-variant btn-variant-red", href:"./crediya"}}/>
                <CardContent img={libreInversion} title={"Libre inversión"} xs={12} md={3} classNameTitle={"title-blue h3 font-weight-bold"} btn={{label:"Ver más", className:"btn btn-primary btn-variant btn-variant-blue", href:"./libreinversion"}}/>
                <CardContent img={educacion} title={"Educación"} xs={12} md={3} classNameTitle={"title-red h3 font-weight-bold"} btn={{label:"Ver más", className:"btn btn-danger btn-variant btn-variant-red", href:"./educacion"}}/>
                <CardContent img={vivienda} title={"Vivienda"} xs={12} md={3} classNameTitle={"title-blue h3 font-weight-bold"} btn={{label:"Ver más", className:"btn btn-primary btn-variant btn-variant-blue", href:"./vivienda"}}/>
              </Row>
            </Container>
            <div className="mt-5">
              <Biseccion  img={metas}
                          title={{label:data[2]?htmlToReactParser.parse(data[2].content):false, className:"h2 text-danger2 text-end"}}
                          subTitle={{label:"Plan de Ahorros", className:"h6 text-blue text-end"}}
                          paragraph={{  label:data[3]?htmlToReactParser.parse(data[3].content):false,
                                      className:"p text-end"}}
                          btn={{label:"Ver más", className:"btn btn-primary btn-variant btn-variant-blue mt-5 col-5", href:"./ahorros"}}
                          />
            </div>
            <div className="mt-5 d-none">
              <Image fluid src={bannerServicios} alt="Programandoweb"/>
            </div>
            <Beneficies btn={{label:"Ver todo", className:"btn btn-danger btn-variant btn-variant-red", href:"./servicios"}}
                        title={{label:data[4]?htmlToReactParser.parse(data[4].content):false, className:"bold h2 text-blue text-start"}} img={bannerServicios}/>
            <BalanceSocial  btn={{label:"Ver todo", className:"btn btn-danger btn-variant btn-variant-red", href:"./beneficios"}}
                            text={data[6]?htmlToReactParser.parse(data[6].content):false}
                            title={{label:data[5]?htmlToReactParser.parse(data[5].content):false, className:"bold h1 text-white text-start"}} img={bannerServicios}/>
            {
              data&&data[7]&&data[7].content&&data[7].content!==''&&(
                <Title id={data[7]} editable={data[7].content} label={data[7]?htmlToReactParser.parse(data[7].content):false} className="text-center text-danger2 font-weight-bold h1 mt-3"/>
              )
            }  

            {
              data&&data[8]&&data[8].content&&data[8].content!==''&&(
                <Title id={data[8]} editable={data[8].content} label={data[8]?htmlToReactParser.parse(data[8].content):false} className="text-center h6 mb-4"/>
              )
            }  
            
            
            <Container>
              <Row className="text-center cardContent mb-5">
                <Col>
                  <Clients/>
                </Col>
              </Row>
            </Container>
            <Container className="d-none">
              <Row className="text-center cardContent mb-5 bg-warning pt-5 pb-5">
                <Col>
                    <Title label={"SOMOS UN PRODUCTO COLOMBIANO"} className="text-center font-weight-bold h1 mt-3"/>
                    <Title label={"Fondo de empleados de Súpertiendas y Drogerías Olípica S.A NIT 860.518.842-1"} className="text-center h6 mb-4"/>
                </Col>
              </Row>
            </Container>
            <Carousel type={2}  classNameContentHeader="content-header3 position-absolute"
                                classNameTitle={"text-danger col-9 h5"}
                                title={data[9]?htmlToReactParser.parse(data[9].content):false}
                                text1="Podrás consultar: "
                                text2={data[10]?htmlToReactParser.parse(data[10].content):false}
                                classNameText2={"text-dark "}
                                classNameImg={" img-mini "}
                                event={setOpen}
                                src={banner3}
                                src_xs={banner3_xs}/>
            <Carousel type={2}  classNameContentHeader="content-header4 position-absolute"
                                classNameTitle={"text-danger col-12 col-sm-9 h5"}
                                title={data[11]?htmlToReactParser.parse(data[11].content):false}
                                text1={data[12]?htmlToReactParser.parse(data[12].content):false}
                                text2="•"
                                classNameText2={"text-dark d-none-d-sm-block"}
                                src={banner4}
                                url="./conocenos"
                                textUrl="Contactar"
                                src_xs={banner4_xs}
                                />
          </>
}

export default App
