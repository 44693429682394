import { useState , useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/ahorros/img1.png';
import {post} from '../../../helpers/api';
const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();



function Collap({row}) {
  const [open, setOpen] = useState(false);
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   useState(false);

  useEffect(() => {
    // Verifica la existencia de la clave 'user' en localStorage al cargar el componente
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  const handleClick=()=>{
    post("api/sections/destroy/"+row.id).then((response)=>{
      document.location.reload();
    })
  }

  return (
    <>
      {
        /*
          <Textarea component={data[1]?htmlToReactParser.parse(data[1].content):false} id={1} data={data}/>
        */
      }
      <div>
          {!open&&(
            <Row className="justify-content-center border-botton">
              <Col  className="text-start pt-1"  xs={12} md={7}>
                <div className='' style={{height:"240px",overflow:"hidden"}}>
                  {
                    htmlToReactParser.parse(row.content)
                  } 
                </div>
              </Col>
              <Col  className="text-start pt-1"  xs={12} md={4}>
                  <Image src={row&&row.image&&row.image!=='NA'?row.image:img1} fluid/>
              </Col>                  
              <Col  className="text-start pt-1"  xs={12} md={11}>
                  <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    >
                    Leer Artículo completo
                  </Button>
                  {
                    isUserLoggedIn&&(
                      <div className='btn btn-secondary ms-1' onClick={()=>{handleClick()}}>
                        Borrar
                      </div>
                    )
                  }
                  <hr/>
              </Col>
            </Row>  
          )}                  
          <Collapse in={open}>
            <div id="example-collapse-text">
              <Row className="justify-content-center border-botton">
                <Col  className="text-start pt-1"  xs={12} md={7}>
                  {
                    htmlToReactParser.parse(row.content)
                  }                                
                </Col>                  
                <Col  className="text-start pt-1"  xs={12} md={4}>
                  <Image src={row&&row.image&&row.image!=='NA'?row.image:img1} fluid/>
                </Col>                  
                <Col  className="text-start pt-1"  xs={12} md={11}>
                  <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    >
                    Cerrar Artículo
                  </Button>
                  <hr/>
                </Col>
              </Row>                                                              
            </div>
          </Collapse>        
      </div>
    </>
  );
}

export default Collap;