import React from 'react';
import Home from './app/home';
import Sarlaft from './app/home/Sarlaft';
import AboutUs from './app/home/AboutUs';
import Bienestar from './app/home/Bienestar';
import Salud from './app/home/Salud';
import Seguros from './app/home/Seguros';
import Turismo from './app/home/Turismo';
import KnowUs from './app/home/KnowUs';
import Services from './app/home/Services';
import Crediya from './app/home/Crediya';
import Libreinversion from './app/home/Libreinversion';
import Educacion from './app/home/Educacion';
import EducacionSinCredito from './app/home/EducacionSinCredito';
import Vivienda from './app/home/Vivienda';
import Ahorros from './app/home/Ahorros';
import Header from './app/common/Header';
import Footer from './app/common/Footer';
import SocialNetwork from './app/common/SocialNetwork';
import SolicitudCredito from './app/common/SolicitudCredito';
import DatosSolicitudCredito from './app/common/DatosSolicitudCredito';

import FondoMutual from './app/home/FondoMutual';
import SedeSocial from './app/home/SedeSocial';

import AuthLogin from './app/Auth/Login';


import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';

function App() {

  return  <>
            <SocialNetwork/>
            <Header/>
              <BrowserRouter>
                <Routes>
                  <Route  path="/" element={<Home/>}/>
                  <Route  path="/sarlaft" element={<Sarlaft/>}/>
                  <Route  path="/nosotros" element={<AboutUs/>}/>
                  <Route  path="/bienestar" element={<Bienestar/>}/>
                  <Route  path="/seguros" element={<Seguros/>}/>
                  <Route  path="/salud" element={<Salud/>}/>
                  <Route  path="/turismo" element={<Turismo/>}/>
                  <Route  path="/conocenos" element={<KnowUs/>}/>
                  <Route  path="/servicios" element={<Services/>}/>
                  <Route  path="/crediya" element={<Crediya/>}/>
                  <Route  path="/libreinversion" element={<Libreinversion/>}/>
                  <Route  path="/educacionsc" element={<EducacionSinCredito/>}/>
                  <Route  path="/educacion" element={<Educacion/>}/>
                  <Route  path="/vivienda" element={<Vivienda/>}/>
                  <Route  path="/ahorros" element={<Ahorros/>}/>
                  <Route  path="/entretenimiento" element={<Bienestar/>}/>
                  <Route  path="/sede_social" element={<SedeSocial/>}/>
                  <Route  path="/fondo_mutual" element={<FondoMutual/>}/>
                  <Route  path="/sarlaft/solicitud-credito" element={<SolicitudCredito/>}/>
                  <Route  path="/sarlaft/datos-credito" element={<DatosSolicitudCredito/>}/>
                  <Route  path="/auth/login" element={<AuthLogin/>}/>
                </Routes>
              </BrowserRouter>
            <Footer/>
          </>
}

export default App;
