import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import {get} from '../../../helpers/api';
import Upload from '../../common/Upload';
import Textarea from '../../common/Textarea';
import {  Empty} from '../../convenios/todos'

const Biseccion=({img})=>{
  const   [data2,setData2] =React.useState([])
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/turismo_icons_b2").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData2(response.data.items)  
      }      
    })    
  },[])
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <div className="row justify-content-center">
                  {
                      data2&&data2.map&&data2.map((row,key)=>{
                        return  <Col  className="text-start mb-3"  xs={12} md={4} key={key}>                        
                                  <Empty data={row} />
                                  {
                                    isUserLoggedIn&&(
                                      <Textarea   section_id="521"  
                                                  alias="turismo_icons_b2" 
                                                  sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                  id={key} 
                                                  data={data2} 
                                                  upload={true}
                                      />
                                    )
                                  }
                                </Col>                  
                      })
                    }
                </div>
                <Row>
                  <Col>
                    {
                      isUserLoggedIn&&(
                        <div className='row'>
                          <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                            <Textarea section_id="521"  
                                      alias="turismo_icons_b2" 
                                      sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                      id={"new"} 
                                      data={{}} 
                                      upload={true}
                            /> Nuevos items 
                          </div>
                        </div>    
                      )
                    }     
                  </Col>
                </Row>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
