import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import {get} from '../../../helpers/api';
import Title from '../../common/Title'
import Textarea from '../../common/Textarea';
import {  Empty} from '../../convenios/todos'


const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();



const Biseccion=({img,data})=>{
  const   [data2,setData2] =React.useState([])
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  
  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    get("api/s/turismo_icons").then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData2(response.data.items)  
      }      
    })    
  },[])

  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-start"  xs={12} md={5}>
                {
                  data&&data[2]&&(
                    <Title id={data[2]} editable={data[2].content} label={data[2]?htmlToReactParser.parse(data[2].content):false} className="text-start h2 mt-3 text-blue"/>
                  )
                }                
                {
                  data&&data[3]&&(
                    <Title id={data[3]} editable={data[3].content} label={data[3]?htmlToReactParser.parse(data[3].content):false} className="text-start"/>
                  )
                }
                <Row className="justify-content-center">

                  {
                    data2&&data2.map&&data2.map((row,key)=>{
                      return  <Col  className="text-start mb-3"  xs={12} md={4} key={key}>                        
                                <Empty data={row} />
                                {
                                  isUserLoggedIn&&(
                                    <Textarea   section_id="520"  
                                                alias="turismo_icons" 
                                                sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                                id={key} 
                                                data={data2} 
                                                upload={true}
                                    />
                                  )
                                }
                              </Col>                  
                    })
                  }
                </Row>
                <Row>
                  <Col>
                    {
                      isUserLoggedIn&&(
                        <div className='row'>
                          <div className='col p-3 mt-1' style={{background:"#f2f2f2"}}>
                            <Textarea section_id="520"  
                                      alias="turismo_icons" 
                                      sizes="Las medidas para banner en cabecera son: 264px x 166px"  
                                      id={"new"} 
                                      data={{}} 
                                      upload={true}
                            /> Nuevos items 
                          </div>
                        </div>    
                      )
                    }     
                  </Col>
                </Row>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
