import Carousel from '../common/CarouselNew'
import Title from '../common/Title'
import Biseccion from '../common/turismo/Biseccion'
import Biseccion2 from '../common/turismo/Biseccion2'
import img1 from '../../assets/images/turismo/nosotros-programandoweb.png'
import img2 from '../../assets/images/turismo/nosotros-2-programandoweb.png'
import banner1 from '../../assets/images/turismo/banner-programandoweb.png'
import React from 'react';
import {get} from '../../helpers/api';
import Upload from '../common/Upload';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{
  const   [data,setData] =React.useState([])
  const   [banner,setBanner]  = React.useState(false)
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      if (response&&response.data&&response.data.banners&&response.data.banners) {
        setBanner(response.data.banners)          
      }
    })
  },[])
  return  <>
            <div className="">
              <Upload/>
              <Carousel banner={banner} title="Turismo"  classNameTitle=" text-danger " text1="" text2="" src={banner1}/>
            </div>

            {
              data&&data[0]&&(
                <Title id={data[0]} editable={data[0].content} label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h2 mt-3 text-danger2"/>
              )
            }


            {
              data&&data[1]&&(
                <Title id={data[1]} editable={data[1].content} label={data[1]?htmlToReactParser.parse(data[1].content):false} className="text-center h6 mb-4 "/>
              )
            }
            
            <Biseccion  img={img1}
                        data={data}
                        title={{label:"Aquí encontrarás aulas", className:"h5 text-blue text-start "}}
                        paragraph={{  label:"FESOL le ofrece a los asociados diferentes cursos como natación o primeros auxilios, además en diplomados en caso de ser enfermeros o estudiante de enfermería.", className:"p text-start mb-4"}}/>
            
            {
              data&&data[4]&&(
                <Title id={data[4]} editable={data[4].content} label={data[4]?htmlToReactParser.parse(data[4].content):false} className="text-center h2 mt-3 text-blue"/>
              )
            }

            <Biseccion2 img={img2}
                        data={data}
                        title={{label:"Aquí encontrarás aulas", className:"h4 text-blue text-start mt-5 pt-5"}}
                        paragraph={{  label:"Se Ofrecen talleres en competencias blandas que motíven el éxito laboral, de acuerdo a la necesidad y requerimiento empresarial", className:"p text-start mb-4"}}/>
          </>
}

export default App
