import React, { useState } from 'react';
import { post } from '../../helpers/api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";


const App = () => {
    const navigate          =   useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
        // Utiliza la función post para enviar los datos al servidor
        const response = await post('api/login', formData);

        if (response.data && response.data.user) {
            // Almacena la información del usuario en localStorage
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
            
            // Navega a la página "/user"
            navigate("/");
        }
        } catch (error) {
        console.error('Error al enviar el formulario:', error);
        }
    };

  return (
    <Container>
      <Row className="justify-content-center align-items-center text-center">
        <Col xs="12" md="4" style={{ minHeight: '50vh' }}>
          <Container>
            <Row>
              <Col>
                Iniciar sesión administrativa
              </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs="12" className='text-center'>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="Ingrese su correo electrónico"
                      className='mb-2 mt-2'
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" className='text-center'>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Contraseña"
                      value={formData.password}
                      className='mb-2'
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className='text-center'>
                  <Button variant="primary" type="submit">
                    Iniciar Sesión
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
