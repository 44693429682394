import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/ahorros/img5.png'
import img2 from '../../../assets/images/ahorros/img6.png'
import img3 from '../../../assets/images/ahorros/img7.png'
import Textarea from '../../common/Textarea';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Biseccion=({title,paragraph,data})=>{
  return  <Container>
            <Row className="justify-content-center mb-5 pb-5">
              <Col  className="text-start"  xs={8}>
                <Row className="justify-content-center">
                  <Col  className="text-start mb-3"  xs={12} md={4}>
                    <Image src={img1} fluid/>
                    <h3 className=" h6 text-center text-danger2">Ahorro Voluntario</h3>
                    <div className="h-300">
                      {
                        data&&data[1]&&(
                          <Textarea component={data[4]?htmlToReactParser.parse(data[4].content):false} id={4} data={data}/>          
                        )
                      }                      
                      {
                        data[4]?htmlToReactParser.parse(data[4].content):false
                      }
                    </div>
                    <div className="text-center">
                      <a className="btn btn-danger btn-variant" href="mailto:servicioalasociado@fesol.com.co?subject=%C2%A1Deseo%20aperturar%20un%20ahorro%20voluntario!&body=Buenos%20d%C3%ADas%20cordial%20saludo.%20%0A%0ADeseo%20aperturar%20un%20ahorro%20voluntario%20por%20un%20valor%20de%20(COLOCAR%20VALOR%20AQU%C3%8D%20Y%20TENGA%20EN%20CUENTA%20SI%20SUS%20DESCUENTOS%20SON%20QUINCENALES%20O%20MENSUALES)%0A%0ANOMBRES%3A%20%0AAPELLIDOS%3A%0AC%C3%89DULA%20DE%20CIUDADAN%C3%8DA%3A%0ACELULAR%3A%0A%0A%0A%C2%A1Por%20favor%20anexar%20su%20c%C3%A9dula%20de%20ciudadan%C3%ADa%20en%20este%20correo!%20">Solicitar</a>
                    </div>
                  </Col>
                  <Col  className="text-start mb-3"  xs={12} md={4}>
                    <Image src={img2} fluid/>
                    <h3 className=" h6 text-center text-blue">Ahorrito FESOL</h3>
                    <div className="h-300">
                      {
                        data&&data[5]&&(
                          <Textarea component={data[5]?htmlToReactParser.parse(data[5].content):false} id={5} data={data}/>          
                        )
                      }
                      {data[5]?htmlToReactParser.parse(data[5].content):false}
                    </div>
                    <div className="text-center">
                      <a className="btn btn-primary btn-variant" href="mailto:servicioalasociado@fesol.com.co?subject=%C2%A1Deseo%20aperturar%20un%20ahorro%20voluntario!&body=Buenos%20d%C3%ADas%20cordial%20saludo.%20%0A%0ADeseo%20aperturar%20un%20Ahorrito%20Fesol%20por%20un%20valor%20de%20(COLOCAR%20VALOR%20AQU%C3%8D%20Y%20TENGA%20EN%20CUENTA%20SI%20SUS%20DESCUENTOS%20SON%20QUINCENALES%20O%20MENSUALES)%0A%0ANOMBRES%3A%20%0AAPELLIDOS%3A%0AC%C3%89DULA%20DE%20CIUDADAN%C3%8DA%3A%0ACELULAR%3A%0A%0A%0A%C2%A1Por%20favor%20anexar%20su%20c%C3%A9dula%20de%20ciudadan%C3%ADa%20en%20este%20correo!%20">Solicitar</a>
                    </div>
                  </Col>
                  <Col  className="text-start mb-3"  xs={12} md={4}>
                    <Image src={img3} fluid/>
                    <h3 className=" h6 text-center text-danger2">Ahorro programado para vacaciones</h3>
                    <div className="h-300">
                      {
                        data&&data[6]&&(
                          <Textarea component={data[6]?htmlToReactParser.parse(data[6].content):false} id={6} data={data}/>          
                        )
                      }
                      {data[6]?htmlToReactParser.parse(data[6].content):false}
                    </div>
                    <div className="text-center">
                      <a className="btn btn-danger btn-variant" href="mailto:servicioalasociado@fesol.com.co?subject=%C2%A1Deseo%20aperturar%20un%20ahorro%20voluntario!&body=Buenos%20d%C3%ADas%20cordial%20saludo.%20%0A%0ADeseo%20aperturar%20un%20Ahorro%20Programado%20para%20Vacaciones%20por%20un%20valor%20de%20(COLOCAR%20VALOR%20AQU%C3%8D%20Y%20TENGA%20EN%20CUENTA%20SI%20SUS%20DESCUENTOS%20SON%20QUINCENALES%20O%20MENSUALES)%0A%0ANOMBRES%3A%20%0AAPELLIDOS%3A%0AC%C3%89DULA%20DE%20CIUDADAN%C3%8DA%3A%0ACELULAR%3A%0A%0A%0A%C2%A1Por%20favor%20anexar%20su%20c%C3%A9dula%20de%20ciudadan%C3%ADa%20en%20este%20correo!%20">Solicitar</a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
}

export default Biseccion
