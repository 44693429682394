import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import logo from '../../assets/images/logo-fesol-home-programandoweb.svg'
import logo2 from '../../assets/images/icons/pse.png'
import logo3 from '../../assets/images/icons/cir-pqr.png'
import btn from '../../assets/images/home/boton_oficina_virtual.svg'
import {post} from '../../helpers/api'
import Modal from './Modal';
import Button from 'react-bootstrap/Button';


const string_='ALURA ANIMAL HEALTH & NUTRITION SAS,BANCO SERFINANZA,BUSCAMOS S. A. S.,C. C. PLAZA DE LAS AMERICAS P. H.,CENTRO COMERCIAL DE SUBA CENTRO SUBA P. H.,CENTRO COMERCIAL PORTAL DE LA 80 - P.H.,CENTRO COMERCIAL Y EMPRESARIAL METROPOLIS,CONFIANZA 21 S. A. S.,DESARROLLO QUIMICO FARMACEUTICO S. A. S.,EFIVENTAS Y SERVICIOS S. A. S.,ESTRATEGIAS S. A. S.,EXTERNOS,FUNDACION OLIMPICA,GESTICA,GRUPO UNIPHARM S. A.,HB HUMAN BIOSCIENCE S. A. S.,INCOPAC SOCIEDAD DE INV. DE LA COSTA PACIFICA,INVERSIONES & OPER.,COMERCIALES DEL SUR,JULE GLOBAL TRADING & CONSULTING S.A.S,LABORATORIOS BAGO DE COLOMBIA S. A. S.,LABORATORIOS BEST S. A.,MSN LABS AMERICA S. A. S.,OLIMPICA S. A.,PANIFICADORA DEL LITORAL,PENSIONADOS,PORTALES URBANOS S. A.,PRODUCTOS Y SERVICIOS SCALPI S.A.S,SERQUERER S. A. S.,SERVICIOS ESPECIALES PARA EMPRESAS (SESPEM),SOCINSA - SOC. COL. DE INV.,SONOVISTA PUBLICIDAD S. A.,VIAJAR LTDA. L ALIANXA,OTRA';

const Form  = ({submit,setSubmit})=>{

  const [inputs,setInputs]      =   React.useState({})
  const [message,setMessage]    =   React.useState(false)

  const onSubmit=(e)=>{
    e.preventDefault()
    post("api/pqr",{...inputs}).then((response)=>{
      setMessage("Su petición ha sido enviada correctamente, el número de solicitud es :" +response.data)
    })
  }

  const onChange=(e)=>{
    let inputs_ = {...inputs}
    inputs_[e.target.name]  =   e.target.value
    setInputs(inputs_)
  }

  React.useEffect(()=>{
    if (submit) {
      setSubmit(false)
      document.getElementById("formulario").submit()
    }
  },[])


  return <>
          <form onSubmit={onSubmit} id="formulario">
            {
              !message?<>
                <div className="row">
                  <div className="col-12 col-sm-4 mb-2">
                    <select className="form-control" name="tipo_pqr"  onChange={onChange} required>
                      <option>Felicitaciones</option>
                      <option>Petición</option>
                      <option>Queja</option>
                      <option>Reclamo</option>
                      <option>Sugerencia</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 mb-2">
                    <input defaultValue={inputs.nombre} type="text" className="form-control" placeholder="Nombre:" name="nombre" onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-4 mb-2">
                    <input defaultValue={inputs.apellido} type="text" className="form-control" placeholder="Apellido:"  name="apellido"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <select className="form-control" name="tipo"  onChange={onChange} required>
                      <option value="">Tipo documento</option>
                      <option>Céduda de ciudadanía</option>
                      <option>Cédula de extrajería</option>
                      <option>Pasaporte</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.nro_documento} type="text" className="form-control" placeholder="Nro documento:"  name="nro_documento"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <select className="form-control" name="empresa"  onChange={onChange} required>
                      <option value="">Empresa en la que trabajas</option>
                      {string_.split(",").map((row,key)=>{
                        return  <option key={key}>{row}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.nro_contacto} type="text" className="form-control" placeholder="Número de contacto"  name="nro_contacto"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.ciudad} type="text" className="form-control" placeholder="Ciudad"  name="ciudad"  onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.departamento} type="text" className="form-control" placeholder="Departamento"  name="departamento"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.direccion_residencia} type="text" className="form-control" placeholder="Dirección de residencia"  name="direccion_residencia"  onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.email} type="email" className="form-control" placeholder="¿A qué correo electrónico deseas recibir respuesta?"  name="email"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 mb-2">
                    <textarea defaultValue={inputs.comentario} placeholder="¿Cuáles son los hechos que en que se fundamenta la PQRS?" className="form-control" name="comentario"  onChange={onChange} required>
                    </textarea>
                  </div>
                  <Button variant="danger" type="submit">
                    Enviar
                  </Button>
                </div>
                <div className="row">
                  <div className="col-12 mb-2 ">
                    <div className="alert alert-success text-center">
                      Sí necesita adjuntar algún archivo soporte a su petición favor enviarlo al correo <b>direccionadministrativa@fesol.com.co</b>
                    </div>
                  </div>
                </div>
              </>:<>
                <div className="row">
                  <div className="col-12 col-sm-4 mb-2">
                    {message}
                  </div>
                </div>
              </>
            }
          </form>
        </>
}

const App=()=>{

  const [openPQR,setOpenPQR]    =   React.useState(false)


  return  <>
            <Modal openPQR={openPQR} setOpenPQR={setOpenPQR} Component={Form}/>
            <Navbar bg="light" expand="lg" className="overflow-hidden2">
              <Container>
                <Row>
                  <Col xs={12} md={9}>
                    <Navbar.Brand href="./">
                      <Image  fluid src={logo} alt="Programandoweb logo" className="logo" />
                    </Navbar.Brand>
                    <Navbar.Brand href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=11429" target="_blank">
                      <Image  fluid src={logo2} alt="Programandoweb logo" className="logo" />
                    </Navbar.Brand>
                    <Navbar.Brand onClick={()=>setOpenPQR(openPQR?false:true)} href="#">
                      <Image  fluid src={logo3} alt="Programandoweb logo" className="logo" />
                    </Navbar.Brand>
                  </Col>  
                  <Col xs={12} md={1} className='mt-4'>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav >

                      </Nav>
                      <Nav className="ms-auto">
                        <Nav.Link href="./sarlaft" className="btn btn-primary text-white btn-conocenos ms-1 me-1">
                          SARLAFT
                        </Nav.Link>
                        <Nav.Link href="./conocenos" className="btn btn-primary text-white btn-conocenos">
                          Conócenos
                        </Nav.Link>
                        <NavDropdown title="Formatos" id="navbarScrollingDropdown" className="ms-2 me-2">
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/20bfe026eb88e8b3d26c0f2d43184f1f.pdf">
                            Afiliación 
                          </NavDropdown.Item>
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/f5c6d4865b0bdfd161c5b2a55ac3ec5b.pdf">
                            Ahorro Voluntario  
                          </NavDropdown.Item>
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/5eea76d3433417fd1e2b8d3f643a8b73.pdf">
                            Crediya y Libranza
                          </NavDropdown.Item>
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/1bd7c0d51681c1089525e36c8c3bff3d.pdf">
                            Descuento de Nómina 
                          </NavDropdown.Item>
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/bd8c1dd793b542600c72ad4bc9cb19f6.pdf">
                            Jardines de Paz
                          </NavDropdown.Item>
                          <NavDropdown.Item href="https://api.fesol.com.co/public/uploads/images/multimedia/bdac38d4245f6ef47ca6a9f0d4975225.pdf">
                            Portafolio Fesol
                          </NavDropdown.Item>
                        </NavDropdown>  
                        <NavDropdown title="Servicios" id="navbarScrollingDropdown" className="ms-2 me-2">
                          {
                            /*
                              <NavDropdown.Item href="./sarlaft">
                                SARLAFT
                              </NavDropdown.Item>  
                            */
                          }
                          
                          <NavDropdown.Item href="./fondo_mutual">
                            Fondo Mutual
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./sede_social">
                            Sede Social
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./educacionsc">
                            Educación
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./entretenimiento">
                            Entretenimiento y Bienestar
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./seguros">
                            Seguros
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./salud">
                            Salud
                          </NavDropdown.Item>
                          <NavDropdown.Item href="./turismo">
                            Turismo
                          </NavDropdown.Item>
                          {
                            /*
                              <NavDropdown.Item href="./servicios">
                                Todos los servicios
                              </NavDropdown.Item>  
                            */
                          }
                          

                        </NavDropdown>
                        <Nav.Link eventkey={2} href="https://private.fesol.com.co/zonap/#/" className="btn btn-danger text-white btn-oficina col-6">
                          <img src={btn} className="w-ico" alt="programandoweb"/>Oficina virtual
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Col>
                </Row>
              </Container>
            </Navbar>
          </>
          
}

export default App
