import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import pqrs from '../../assets/images/icons/pqrs.png';
import superintendencia from '../../assets/images/superintendencia-programandoweb.png';
import {post,get} from '../../helpers/api'
import Modal from './Modal';

const string_='ALURA ANIMAL HEALTH & NUTRITION SAS,BANCO SERFINANZA,BUSCAMOS S. A. S.,C. C. PLAZA DE LAS AMERICAS P. H.,CENTRO COMERCIAL DE SUBA CENTRO SUBA P. H.,CENTRO COMERCIAL PORTAL DE LA 80 - P.H.,CENTRO COMERCIAL Y EMPRESARIAL METROPOLIS,CONFIANZA 21 S. A. S.,DESARROLLO QUIMICO FARMACEUTICO S. A. S.,EFIVENTAS Y SERVICIOS S. A. S.,ESTRATEGIAS S. A. S.,EXTERNOS,FUNDACION OLIMPICA,GESTICA,GRUPO UNIPHARM S. A.,HB HUMAN BIOSCIENCE S. A. S.,INCOPAC SOCIEDAD DE INV. DE LA COSTA PACIFICA,INVERSIONES & OPER.,COMERCIALES DEL SUR,JULE GLOBAL TRADING & CONSULTING S.A.S,LABORATORIOS BAGO DE COLOMBIA S. A. S.,LABORATORIOS BEST S. A.,MSN LABS AMERICA S. A. S.,OLIMPICA S. A.,PANIFICADORA DEL LITORAL,PENSIONADOS,PORTALES URBANOS S. A.,PRODUCTOS Y SERVICIOS SCALPI S.A.S,SERQUERER S. A. S.,SERVICIOS ESPECIALES PARA EMPRESAS (SESPEM),SOCINSA - SOC. COL. DE INV.,SONOVISTA PUBLICIDAD S. A.,VIAJAR LTDA. L ALIANXA,OTRA';



const Form  = ({submit,setSubmit})=>{
  const [inputs,setInputs]      =   React.useState({})
  const [message,setMessage]    =   React.useState(false)

  const onSubmit=(e)=>{
    e.preventDefault()
    post("api/pqr",{...inputs}).then((response)=>{
      setMessage("Su petición ha sido enviada correctamente, el número de solicitud es :" +response.data)
    })
  }

  const onChange=(e)=>{
    let inputs_ = {...inputs}
    inputs_[e.target.name]  =   e.target.value
    setInputs(inputs_)
  }

  React.useEffect(()=>{
    if (submit) {
      setSubmit(false)
      document.getElementById("formulario").submit()
    }
  },[])

  


  return <>
          <form onSubmit={onSubmit} id="formulario">
            {
              !message?<>
                <div className="row">
                  <div className="col-12 col-sm-4 mb-2">
                    <select className="form-control" name="tipo_pqr"  onChange={onChange} required>
                      <option>Felicitaciones</option>
                      <option>Petición</option>
                      <option>Queja</option>
                      <option>Reclamo</option>
                      <option>Sugerencia</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 mb-2">
                    <input defaultValue={inputs.nombre} type="text" className="form-control" placeholder="Nombre:" name="nombre" onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-4 mb-2">
                    <input defaultValue={inputs.apellido} type="text" className="form-control" placeholder="Apellido:"  name="apellido"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <select className="form-control" name="tipo"  onChange={onChange} required>
                      <option value="">Tipo documento</option>
                      <option>Céduda de ciudadanía</option>
                      <option>Cédula de extrajería</option>
                      <option>Pasaporte</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.nro_documento} type="text" className="form-control" placeholder="Nro documento:"  name="nro_documento"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <select className="form-control" name="empresa"  onChange={onChange} required>
                      <option value="">Empresa en la que trabajas</option>
                      {string_.split(",").map((row,key)=>{
                        return  <option key={key}>{row}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.nro_contacto} type="text" className="form-control" placeholder="Número de contacto"  name="nro_contacto"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.ciudad} type="text" className="form-control" placeholder="Ciudad"  name="ciudad"  onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.departamento} type="text" className="form-control" placeholder="Departamento"  name="departamento"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.direccion_residencia} type="text" className="form-control" placeholder="Dirección de residencia"  name="direccion_residencia"  onChange={onChange} required/>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <input defaultValue={inputs.email} type="email" className="form-control" placeholder="¿A qué correo electrónico deseas recibir respuesta?"  name="email"  onChange={onChange} required/>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 mb-2">
                    <textarea defaultValue={inputs.comentario} placeholder="¿Cuáles son los hechos que en que se fundamenta la PQRS?" className="form-control" name="comentario"  onChange={onChange} required>
                    </textarea>
                  </div>
                  <Button variant="danger" type="submit">
                    Enviar
                  </Button>
                </div>
                <div className="row">
                  <div className="col-12 mb-2 ">
                    <div className="alert alert-success text-center">
                      Sí necesita adjuntar algún archivo soporte a su petición favor enviarlo al correo <b>direccionadministrativa@fesol.com.co</b>
                    </div>
                  </div>
                </div>
              </>:<>
                <div className="row">
                  <div className="col-12 col-sm-4 mb-2">
                    {message}
                  </div>
                </div>
              </>
            }
          </form>
        </>
}


const App=()=>{

  const [openPQR,setOpenPQR]    =   React.useState(false)

  const [data , setData]        =   React.useState([])

  React.useEffect(()=>{
    get("api/s/datos_contacto").then((response)=>{
      if (response.data && response.data.datos_contacto	) {
        setData(response.data.datos_contacto	)
      }
    })
  },[])

  return  <>
            <Modal openPQR={openPQR} setOpenPQR={setOpenPQR} Component={Form}/>
            <Container fluid className="footer p-4">
              <Container>
                <Row className="justify-content-md-center text-white" >
                  <Col xs={12} md={3}>
                    <div dangerouslySetInnerHTML={{ __html: data&&data[0]&&data[0].content }} />                    
                  </Col>
                  <Col xs={12} md={3}>
                    <div dangerouslySetInnerHTML={{ __html: data&&data[1]&&data[1].content }} />                    
                  </Col>  
                  <Col xs={12} md={3}>
                    <div dangerouslySetInnerHTML={{ __html: data&&data[2]&&data[2].content }} />                    
                  </Col>                                    
                </Row>
              </Container>
            </Container>
            <Container fluid className="footer-2 p-0">
              <Container className="pb-5">
                <Row className="justify-content-md-center" >
                  <Col xs={12} md={1} className="cursor-pointer" onClick={()=>setOpenPQR(openPQR?false:true)}>
                    <div className=" ">
                      <img src={pqrs} alt="programandoweb"/>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="text-white h6 text-center">
                      Fondo de Empleados de Supertiendas y Droguerías Olímpica S.A.
                      <br/><b>FESOL</b>
                      <br/>Nit 860.518.842-1</div>
                  </Col>
                  <Col xs={12} md={4} className="pt-2">
                    <img src={superintendencia} alt="programandoweb"/>
                    {
                      /* 
                        <img src={facebook} className="social-network" alt="programandoweb"/>  
                        <img src={facebook} className="social-network" alt="programandoweb"/>
                      */
                    }
                    
                    {
                      /*
                        <a rel="noreferrer" href="https://www.instagram.com/instafesol?igsh=dHlremYyeXA0Mmx0" target="_blank">
                          <img src={instagram} className="social-network" alt="programandoweb"/>
                        </a>
                        <img src={youtube} className="social-network" alt="programandoweb"/>
                      */
                    }
                    
                  </Col>
                </Row>
              </Container>
            </Container>
            <Container fluid className="footer-3">
              <Row className="justify-content-md-center" >
                <Col xs={12} md={4} className="text-center p-2 text-white">
                    Todos los derechos reservados Fesol 2022
                </Col>
              </Row>
            </Container>
          </>
}

export default App
