import React from 'react';
import 'draft-js/dist/Draft.css';
import MiModal from '../EditSlider/Modal';
import { post } from '../../../helpers/api';
import EditorEnriquecido from './EditorEnriquecido';
import Upload from '../Upload/Form';
import Switch from '../Switch';
 
function Textarea({data,id,upload,sizes,alias,section_id}) {
    const [inputValue, setInputValue]           =       React.useState(data&&data[id]&&data[id].content);
    const [inputValue2, setInputValue2]         =       React.useState({status:data&&data[id]&&data[id].status});
    const [showModal, setShowModal]             =       React.useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn]   =       React.useState(false);
    const [image, setImage]                     =       React.useState({data:data&&data[id]&&data[id].image});
    const [btnDisabled, setBtnDisabled]         =       React.useState(true);

    React.useEffect(() => {        
        const userExists = localStorage.getItem('user');
        setIsUserLoggedIn(!!userExists);
    }, []);


    const handleButtonClick = () => {
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const onChange=(text)=>{
        setInputValue(text)        
    }

    const onChange2 = (e) => {
        setInputValue2((prev) => {
            return {
            ...prev,
            [e.target.name]: e.target.checked?1:-1,
            };
        });
    };

    React.useEffect(() => {        
      if (upload&&image&&image.data) {
        setBtnDisabled(false)
      }  
      if (!upload) {
        setBtnDisabled(false)
      }
    }, [image]);

    const handleSaveChanges = async () => {
        try {
            if (data&&data[id]&&data[id].id&&inputValue!=='') {
                //console.log(inputValue)
                // Envía los cambios al backend utilizando FormData
                const response = await post('api/sections/edit/'+data[id].id, { content: inputValue,
                                                                                status: inputValue2.status,
                                                                                section_id:section_id||501,
                                                                                alias:alias||"blog-sarlaft",
                                                                                image:image&&image.data||"NA" }, true);
                document.location.reload()    
            }else if (id==='new') {
                const response = await post('api/sections/edit/0',  {       
                                                                        status: inputValue2.status,
                                                                        content: inputValue, 
                                                                        alias:alias||"blog-sarlaft",
                                                                        section_id:section_id||501,
                                                                        image:image&&image.data||"NA"
                                                                    }, true);
                document.location.reload()    
            }
        } catch (error) {
            console.error('Error al comunicarse con el backend:', error);
        }
    };

    return (
        <>  
            {
                isUserLoggedIn&&(
                    <div className='btn btn-secondary btn-sm' onClick={handleButtonClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16"  disabled={!isUserLoggedIn}>
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
                        </svg>                  
                    </div>
                )
            }
            <MiModal    show={showModal} 
                        btnDisabled={btnDisabled}
                        onHide={handleCloseModal} 
                        handleCloseModal={handleCloseModal}
                        handleSaveChanges={handleSaveChanges}
                        component={   <>
                                        <Switch label={"Publicado" }
                                                name="status"
                                                onChange={onChange2} 
                                                defaultChecked={inputValue2.status}                                                
                                        />
                                        <EditorEnriquecido defaultValue={data&&data[id]&&data[id].content} onChange={onChange}/>
                                        {
                                            upload&&(<Upload sizes={sizes} setInputValue={setImage} skipUrl={true}/>)
                                        }
                                    </>                     
                        }   
            /> 

        </>
    );
}

export default Textarea;