import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../assets/images/balance/1.png'
import img2 from '../../assets/images/balance/2.png'
import img3 from '../../assets/images/balance/3.png'
import img4 from '../../assets/images/balance/4.png'
import img5 from '../../assets/images/estados-de-whatsapp.jpg'
import {get,post} from '../../helpers/api';
import VerticalCarousel from './VerticalCarouselNew'
import Upload from '../common/Upload/Form';


const items = [
                  {
                    label:"Se han otorgado 3.371 Créditos para solución de vivienda.",
                    img:img1,
                  },
                  {
                    label:"700 Auxilios para programas de educación técnica y tecnología.",
                    img:img2,
                  },
                  {
                    label:"3.463 Auxilios para educación superior.",
                    img:img1,
                  },
                  {
                    label:"463 Auxilios en capacitación de sistemas.",
                    img:img2,
                  },
              ]


const items2 = [
                  {
                    label:"Se han otorgado 1.371 Créditos para solución de vivienda.",
                    img:img3,
                  },
                  {
                    label:"800 Auxilios para programas de educación técnica y tecnología.",
                    img:img4,
                  },
                  {
                    label:"3.363 Auxilios para educación superior.",
                    img:img3,
                  },
                  {
                    label:"463 Auxilios en capacitación de sistemas.",
                    img:img4,
                  },

              ]

const Biseccion=({img,title,subTitle,paragraph,btn,text})=>{
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  const   [data,setData]        = React.useState({})
  const   [items,setItems]      = React.useState([])
  const   [items2,setItems2]    = React.useState([])
  const   [image,setImage]      = React.useState()

  React.useEffect(() => {
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);
  

  React.useEffect(()=>{
    get("api/s/balance_social").then((response)=>{
      if (response&&response.data&&response.data.balance_social&&response.data.balance_social.map) {
        let data_1  = [];
        let data_2  = [];
        response.data.balance_social.map((row,key)=>{
          
          row.img   =   key % 2 !== 0 ?img1:img2;

          if (key<4) {
            row.label =   row.content;            
            data_1.push(row);
          }else{
            row.label = row.content;
            data_2.push(row);
          }
          setItems(data_1)
          setItems2(data_2)
          return ;
        })        
      }
    })
    get("api/whatsapp").then((response)=>{
      if (response&&response.data) {
        setData(response.data)
        
      }
    })
  },[])


  React.useEffect(()=>{
    if(image&&image.data){
      post('api/banners/add/0',   { 
          group: "whatsapp",
          name:"whatsapp desde el front",
          href:"#",
          url:image.data
      }, true).then(response=>{
        document.location.reload()  
      });      
    }
  },[image])
  

  return  <Container fluid className="position-relative bg-cover-blue">
            <Row className="p-0">
              <Image className="d-none position-absolute bg-absolute p-0 m-0" src={img} fluid/>
              <Col  className="text-center "  xs={12} md={7}>
                <Row className="p-5 mt-5 justify-content-md-center ">
                  <Col className={title.className}  xs={12}>
                    {title.label}
                  </Col>
                  <div className="text-white text-start">"{text}"</div>
                  <Col xs={12} className="mb-4">
                    <VerticalCarousel data={items}/>
                  </Col>
                  {
                    /*
                      <Col xs={12}>
                    <VerticalCarousel data={items2}/>
                  </Col>  
                    */
                  }
                  
                </Row>
              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Row className="p-5 mt-5 justify-content-md-center text-center">
                  <Col className={title.className+" text-center"}  xs={12} >
                    <div className="mb-5">Destacados</div>
                    {
                      isUserLoggedIn&&(
                        <Upload skipLabel={true} skipUrl={true} setInputValue={setImage} sizes="Las medidas para banner en cabecera son: 500px x 900px"/>
                      )
                    }                    
                    <img className="col-12 col-sm-8" src={data&&data.url||img5} alt=""/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
}

export default Biseccion
