import React from 'react';
import Carousel from '../common/CarouselNew'
import Title from '../common/Title'
import Biseccion from '../common/educacion/Biseccion'
import Biseccion2 from '../common/educacion/Biseccion2'
import Biseccion3 from '../common/educacion/Biseccion3'
import banner1 from '../../assets/images/educacion/banner-programandoweb.png'
import banner1_xs from '../../assets/images/educacion/banner-programandoweb.png'
import SolicitudCreditoComponent from '../common/SolicitudCreditoComponent';
import {get} from '../../helpers/api';
import Upload from '../common/Upload';

const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();

const App=()=>{
  const   [data,setData] =React.useState([])
  const   [banner,setBanner]  = React.useState(false)
  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      if (response&&response.data&&response.data.banners&&response.data.banners) {
        setBanner(response.data.banners)          
      }
    })
  },[])
  return  <>
            <div className="">
              <Upload/>
              <Carousel banner={banner} src_xs={banner1_xs} title="Educación" text1="" text2="" classNameTitle="text-danger" src={banner1}/>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 text-font-bold">
                  {
                    data&&data[0]&&data[0].content&&(
                      <Title id={data[0]} editable={data[0].content}  label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h1 mt-3 text-blue"/>
                    )
                  }   
                </div>
              </div>
            </div>
            <Biseccion data={data}/>
            {
              data&&data[2]&&data[2].content&&(
                <Title id={data[2]} editable={data[2].content}  label={data[2]?htmlToReactParser.parse(data[2].content):false} className="text-center h1 mt-3 text-blue"/>
              )
            } 
            {
              data&&data[3]&&data[3].content&&(
                <Title id={data[3]} editable={data[3].content}  label={data[3]?htmlToReactParser.parse(data[3].content):false} className="text-center p  text-dark"/>
              )
            } 
            <Biseccion2/>
            {
              data&&data[4]&&data[4].content&&(
                <Title id={data[4]} editable={data[4].content}  label={data[4]?htmlToReactParser.parse(data[4].content):false} className="text-center h1 mt-3 text-blue"/>
              )
            } 
            {
              data&&data[5]&&data[5].content&&(
                <Title id={data[5]} editable={data[5].content}  label={data[5]?htmlToReactParser.parse(data[5].content):false} className="text-center p  text-dark"/>
              )
            } 
            <Biseccion3/>            
          </>
}

export default App
