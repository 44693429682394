import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import img1 from '../../../assets/images/nosotros/campo-alto-programandoweb.png'
import img2 from '../../../assets/images/nosotros/uni.png'

const Biseccion=({img,title,paragraph})=>{
  return  <Container>
            <Row>
              <Col  className="text-center"  xs={12} md={2}>

              </Col>
              <Col  className="text-center"  xs={12} md={5}>
                <Image src={img} fluid/>
              </Col>
              <Col  className="text-center"  xs={12} md={4}>
                <div className={title.className}>{title.label}</div>
                <div className={paragraph.className}>{paragraph.label}</div>
                <ul className="text-start">
                  <li>Auxiliar administrativo</li>
                  <li>Auxiliar en servicios farmacéuticos</li>
                  <li>Auxiliar en nóminas y prestaciones sociales</li>
                  <li>Auxiliar contable</li>
                </ul>
                <div className="row">
                  <img src={img1} alt="programandoweb" className="col-5" />
                  <img src={img2} alt="programandoweb" className="col-5"/>
                </div>
              </Col>
              <Col  className="text-center"  xs={12} md={1}>

              </Col>
            </Row>
          </Container>
}

export default Biseccion
