import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from '../common/Carousel'
import Title from '../common/Title'
import Textarea from '../common/Textarea';
import {get,post} from '../../helpers/api';
import Upload from '../common/Upload';
import Collapse from '../common/Collapse';
const   HtmlToReactParser = require('html-to-react').Parser;
let     htmlToReactParser = new HtmlToReactParser();


const Sarlaft=()=>{
  const   [data,setData]                        =   React.useState({})
  const   [items, setItems]                     =   React.useState([]);
  const   [banner,setBanner]                    =   React.useState(false)
  const   [pdfs,setPdfs]                        =   React.useState([])
  const   [isUserLoggedIn, setIsUserLoggedIn]   =   React.useState(false);
  

  React.useEffect(() => {
    // Verifica la existencia de la clave 'user' en localStorage al cargar el componente
    const userExists = localStorage.getItem('user');
    setIsUserLoggedIn(!!userExists);
  }, []);

  React.useEffect(()=>{
    const endpoint  = (document.location.pathname==='/'?"api/s":"api/s"+document.location.pathname)
    get(endpoint).then((response)=>{
      if (response&&response.data&&response.data.items) {
        setData(response.data.items)  
      }
      if (response&&response.data&&response.data.sarlaft) {
        setItems(response.data.sarlaft)  
      }
      if (response&&response.data&&response.data.banners&&response.data.banners) {
        setBanner(response.data.banners)          
      }
      if (response&&response.data&&response.data.section_pdf&&response.data.section_pdf) {
        setPdfs(response.data.section_pdf)          
      }
    })
  },[])

  const handleRemove=(row)=>{
    try {
      post("api/sections/destroy/"+row.id).then((response)=>{
        document.location.reload();
      })
    } catch (error) {
      console.log(error)
    }
  }

  return  <>
            <div className="">
              <Upload/>
              {banner && (
                  <div className="d-block d-sm-none">
                      <img src={"https://api.fesol.com.co/public/uploads/images/sarlaft.jpeg"} className='img-fluid' />
                  </div>
              )}
              {banner && (
                  <div className="d-none d-sm-block">
                      <Carousel banner={banner} src_xs={banner.url} src={banner.url} title="" classNameTitle=" text-blue " text1="" text2="" />
                  </div>
              )}             
            </div>
            {
              data&&data[0]&&data[0].content&&(
                <Title id={data[0]} editable={data[0].content}  label={data[0]?htmlToReactParser.parse(data[0].content):false} className="text-center h1 mt-3 text-danger"/>
              )
            }   

            <div>
              {
                data&&data[1]&&(
                  <Textarea component={data[1]?htmlToReactParser.parse(data[1].content):false} id={1} data={data}/>          
                )
              }
              <Container>
                <Row className="justify-content-center">
                  <Col  className="text-start pt-4"  xs={12} md={8}>
                    {data[1]?htmlToReactParser.parse(data[1].content):false}
                  </Col>                  
                </Row>                  
              </Container>
              {
                data&&data[2]&&data[2].content&&(
                  <Title id={data[2]} editable={data[2].content}  label={data[2]?htmlToReactParser.parse(data[2].content):false} className="text-center h1 mt-3 text-danger"/>
                )
              }  
              <Container>
                {
                  isUserLoggedIn&&(
                    <Row className="justify-content-center">
                      <Col  className="text-center pt-4"  xs={12} md={3} >                        
                        <Textarea  id={"new"} data={data} upload={true}/>
                      </Col>                  
                    </Row>                  
                  )
                }

                {
                  items&&items.map&&items.map((row,key)=>{
                    if (row.image==='NA') {
                      return;
                    }
                    return  <Collapse row={row} key={key}/>
                  })
                }
                
              </Container>
            </div>   
            <div style={{backgroundColor:"#f2f2f2"}}>
              <Container>
                <Row className="justify-content-center">
                  <Col  className="text-start pt-4 pb-4"  xs={12} md={11}>
                    Documentos de interés
                    <br/>
                    {
                      data&&data[3]&&(
                        <Textarea alias="section_pdf" section_id={"505"} id={"new"} data={data} upload={true}/>
                      )
                    }
                  </Col>
                </Row>    
                <Row className="justify-content-start">
                  {
                    pdfs&&pdfs.map&&pdfs.map((row,key)=>{
                      return  <Col key={key} className="text-start pb-4 bg-white border mb-3"  xs={12} md={3}>
                                  <div dangerouslySetInnerHTML={{ __html: row.content }} />                                                                      
                                  <a className='btn btn-primary mr-1' href={row.image} target='_blank'>
                                    Descargar
                                  </a>

                                  {
                                    isUserLoggedIn&&(
                                      <div className='ms-1 btn btn-secondary' onClick={()=>{handleRemove(row)}}>
                                        Eliminar
                                      </div>
                                    )
                                  }
                                  
                              </Col>                  
                    })
                  }
                </Row>                  
              </Container>
            </div> 
            <div>
              <Container>
                <Row className="justify-content-center">
                  <Col  className="text-start pt-4 pb-4"  xs={12} md={11}>
                    {
                      data&&data[3]&&(
                        <Textarea component={data[3]?htmlToReactParser.parse(data[3].content):false} id={3} data={data}/>          
                      )
                    }
                  </Col>
                </Row>    
                <Row className="justify-content-center">
                  <Col  className="text-start pb-4"  xs={12} md={11}>
                    {data[3]?htmlToReactParser.parse(data[3].content):false}
                  </Col>                  
                </Row>                  
              </Container>
            </div>                  

          </>
}

export default Sarlaft
